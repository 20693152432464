const cartModal = {
    title: {
        success: 'Order is processed',
        noSuccess: 'Order not placed!',
    },
    subTitle: {
        success: 'Expect delivery at the specified time',
        noSuccess: 'Repeat order or choose another payment method',
    },
    btnClose: 'OK',
};

export default cartModal;
