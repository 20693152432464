const cartModal = {
    title: {
        success: 'Sipariş verildi',
        noSuccess: 'Sipariş verilmedi!',
    },
    subTitle: {
        success: 'Belirtilen zamanda teslimatı bekliyoruz',
        noSuccess: 'Siparişi tekrarlayın veya başka bir ödeme yöntemi belirtin',
    },
    btnClose: 'Anlaşılır',
};

export default cartModal;
