const cart = {
    product: {
        title: 'Корзина',
        relatedTitle: 'С этим товаром обычно берут',
        cartEmpty: 'В корзине пусто :(',
        backToCatalog: 'К покупкам',
        next: 'Далее',
        autoProduct: 'Добавлено автоматически',
        returnedBottles: {
            title: 'Возвратная тара',
            buttons: {
                addBottles: 'Добавить тару',
                skipBottles: 'Продолжить без тары',
            },
        },
        deletedProduct: 'Товар удален',
        restoreProductBtn: 'Восстановить',
    },
    order: {
        title: 'Доставка',
        payment: 'Онлайн-оплата',
        addAddress: {
            title: 'Укажите адрес доставки',
            subtitle: 'Чтобы курьер смог вас найти',
            btnOpen: 'Добавить адрес',
            loading: 'Загрузка адресов',
            back: 'Назад',
            noFoundAdress: 'Не нашел нужный адрес',
            manuallyLocation: 'Укажите точное место на карте, куда нужно доставить заказ',
            mainLocation: 'Населенный пункт: ',
            secondaryLocation: 'Улица / район: ',
            coordinates: 'Координаты: ',
            btnConfirm: 'Подтвердить',
            btnNext: 'Далее',
            fullAddress: 'Указать полный адрес',
            street: 'Улица',
            house: 'Дом',
            entrance: 'Подъезд',
            floor: 'Этаж',
            room: 'Кв / Офис',
            name: 'Название, например Дом или Офис',
            noRoomNumber: 'Без номера квартиры',
            comment: 'Комментарий',
            errorLocationSearch: 'К сожалению, ваш адрес не найден',
            placeholder: 'Введите город, улицу и дом',
            notFound: 'Ничего не найдено',
        },
        addressField: {
            title: 'Адрес доставки',
            noAddresses: 'Нет адресов',
            addAddress: 'Указать адрес',
            changeAddress: 'Указать другой адрес',
            city: '',
            street: '',
            house: 'д.',
            entrance: 'п.',
            floor: 'эт.',
            room: 'кв.',
        },
        deliveryTime: {
            title: 'Время доставки',
            loading: 'Загрузка интервалов',
            noTimeForAdress: 'Нет доступного времени для указанного адреса',
        },
        bonusField: {
            title: 'Бонусные баллы',
            useBonuses: 'Использовать баллы',
            notSufficientBonuses: 'Недостаточно баллов для оплаты заказа',
            firstOrder: 'Сделайте заказ чтобы начать копить баллы.',
            payExtra: 'Нужно будет доплатить: {{value}}',
            more: 'Подробнее',
        },
        phoneNumber: 'Номер телефона',
        enterPhoneNumber: 'Укажите номер телефона',
        notValidPhoneNumber: 'Укажите корректный номер телефона',
        orderCost: 'Заказ и стоимость',
        orderSum: 'Сумма заказа: ',
        deliveryCost: 'Доставка: ',
        paymentMethod: 'Способ оплаты',
        paymentLoading: 'Загрузка cпособов оплаты',
        errorPaymentLoading: 'Ошибка получения типов оплаты',
        promocodeTitle: 'Введите промокод',
        promocode: 'Промокод',
        apply: 'Применить',
        comment: 'Комментарий к заказу',
        pc: ' шт.',
        addressNotSelected: 'Адрес доставки не выбран',
    },
    footer: {
        returnedBottles: 'Бутылей к возврату',
        helpMessageOk: 'Ок, понятно',
        infoMessage: 'Возвратной тары не может быть больше, чем бутылей с водой',
        sum: 'Сумма заказа: ',
        bonuses: ' баллов',
        shortBonuses: ' б',
        delivery: 'Доставка: ',
        сheckout: 'Оформить заказ',
        total: 'К оплате: ',
    },
};

export default cart;
