const cartDrawer = {
    cart: {
        title: 'Корзина',
        relatedTitle: 'С этим товаром обычно берут',
        empty: 'В корзине пусто :(',
        backButton: 'Назад',
    },
    auth: {
        title: 'Авторизация',
        description:
            'Войдите или зарегистрируйтесь, чтобы делать заказы, получать бонусы и многое другое',
        btnAuth: 'Вход / Регистрация',
        btnAnon: 'Заказ без регистрации',
    },
    login: {
        title: 'Вход / Регистрация',
    },
    anon: {
        title: 'Укажите номер',
        description:
            'чтобы оператор смог подтвердить заказ, а курьер связаться с вами в случае необходимости',
        btnSend: 'Отправить',
        orAuth: {
            or: 'или',
            auth: 'Авторизуйтесь',
            description:
                'чтобы видеть историю покупок, копить баллы, и повторять заказы в несколько кликов',
        },
    },
    back: 'Назад',
};

export default cartDrawer;
