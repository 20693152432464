const layout = {
    header: {
        company: 'Şirkət',
        user: 'İstifadəçi',
        subtitle: 'Şəhərdaxili çatdırılma',
        buttonBack: 'Geri',
        buttonCheckout: 'Sifariş verin',
    },
    mobileMenu: {
        company: 'Şirkət Parametrləri',
        user: 'Mənim profilim',
    },
    footer: {
        contractOffer: 'Təklif müqaviləsi',
        privacyPolicy: 'Məxfilik Siyasəti',
        userAgreement: 'İstifadəçi müqaviləsi',
        partnerWebsite: 'Tərəfdaş veb saytı',
    },
    buttons: {
        logout: 'Çıxın',
    },
};

export default layout;
