const banners = {
    type: {
        clickBanner: 'Click banner',
        infoBanner: 'Info banner',
        storyBanner: 'Story',
        productSetBanner: 'Product set',
    },
    preview: 'Preview',
    makeOrder: 'Make order',
};

export default banners;
