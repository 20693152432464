const catalogBottomSection = {
    title: 'Подробнее',
    aboutCompany: {
        title: 'О компании',
    },
    deliveryPayment: {
        title: 'Доставка и оплата',
        shippingTime: 'Режим работы доставки:',
        titlePaymentMethods: 'Способы оплаты:',
        paymentMethods: {
            softInvoice: 'Мягкая накладная',
            sberbankRecurring: 'Автоплатеж Сбербанк',
            yandexKassa: 'Яндекс Касса',
            yooMoneyRecurring: 'Автоплатеж ЮМани',
            offlineAcquiring: 'Банковский картой',
            cash: 'Наличными',
            googlePay: 'Google Pay',
            applePay: 'Apple Pay ',
            sberbank: 'Сбербанк',
            loyalty: 'Оплата баллами',
            contract: 'По договору',
            eximbank: 'Эксимбанк',
            sberbankOnlineByPhone: 'СберPay',
            sberbankOnlineByQr: 'СберPay (QR код)',
            liqpay: 'Liqpay',
            tinkoff: 'Тинькофф',
            sberbanksbp: 'Система быстрых платежей (СБП)',
            contractForLegalPerson: 'Оплата по счету для юр. лиц',
        },
    },
    downloadAppModal: {
        title: 'Скачать приложение',
        text: 'Скачай приложение и делай заказы удобно!',
        textMobile: 'Скачайте приложение и делайте заказы удобно',
        img: 'Мобильное приложение',
    },
};

export default catalogBottomSection;
