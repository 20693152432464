const profile = {
    title: 'My profile',
    myAddress: 'My addresses',
    historyOrders: 'Order history',
    messages: 'Support Chat',
    bonuses: 'Your points',
    share: 'Share link',
    delivery: 'Shipping and payment',
    aboutCompany: 'About company',
    downloadApp: 'Download app',
    signOut: 'Sign out',
    btnLogoutOk: 'Yes, log out',
    deleteAccount: 'Delete account',
    modalDeleteAccount: {
        title: 'Delete account?',
        btnDelete: 'Yes',
        btnClose: 'No',
    },
    messageSignOut:
        'When you exit the profile, all products will be removed from the basket. Sign out of profile?',
    userPlaceholder: 'User name',
    emailPlaceholder: 'email',
    copy: 'Link copied',
    history: {
        repeat: 'Repeat order',
        more: 'More',
        order: 'Order №',
        cancel: 'Cancel order',
        amount: 'Amount: ',
        deliveryAmount: 'Delivery: ',
        quantity: 'Quantity: ',
    },
};

export default profile;
