export enum PaymentCodes {
    cash = '1',
    contract = '2',
    card = '3',
    apple = '6',
    sbp = '8',
    online = '10',
    qr = '11',
    google = '12',
    bonus = '15',
}
