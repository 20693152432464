const catalogBottomSection = {
    title: 'Daha ətraflı',
    aboutCompany: {
        title: 'Şirkət haqqında',
    },
    deliveryPayment: {
        title: 'Çatdırılma və ödəniş',
        shippingTime: 'Çatdırılma saatları:',
        titlePaymentMethods: 'Ödəniş üsulları:',
        paymentMethods: {
            softInvoice: 'Yumşaq faktura',
            sberbankRecurring: 'Sberbank Avtomatik Ödəmə',
            yandexKassa: 'Yandex Kassa',
            yooMoneyRecurring: 'Yumani Avtomatik Ödəmə',
            offlineAcquiring: 'Bank kartı',
            cash: 'Nəğd pul',
            googlePay: 'Google Pay',
            applePay: 'Apple Pay ',
            sberbank: 'Sberbank',
            loyalty: 'Bonus xallarla ödəniş',
            contract: 'Müqavilə ilə',
            eximbank: 'Müqavilə ilə',
            sberbankOnlineByPhone: 'Sberrau',
            sberbankOnlineByQr: 'Sberrau (QR kodu)',
            liqpay: 'Liqpay',
            tinkoff: 'Tinkoff',
            sberbanksbp: 'Sürətli ödəmə sistemi (SBP)',
            contractForLegalPerson: 'Hüquqi şəxslər üçün hesab ödənişi',
        },
    },
    downloadAppModal: {
        title: 'Proqramı yükləyin',
        text: 'Proqramı yükləyin və sifarişlərinizi rahat edin!',
        textMobile: 'Proqramı yükləyin və rahatlıqla sifariş verin',
        img: 'Mobil proqram',
    },
};

export default catalogBottomSection;
