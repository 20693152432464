const catalog = {
    addressSelector: {
        title: 'Adreslerim',
        addAddress: '+ Adres Ekle',
    },
    enterAddressPanel: {
        title: 'İlgili ürünler ve fiyatlar için adresi belirtin',
        buttonMap: 'Haritada göster',
        buttonNotFoundAddress: 'Adresi bulamadım',
        addAddress: '+ Adres Ekle',
        inputPlaceholder: 'Şehri, sokağı ve evi girin',
    },
    confirmDeleteAddress: 'Adresi gerçekten silmek istiyor musunuz?',
    confirmClearCart:
        'Gerçekten seçilen adresi silmek istiyor musunuz? Sepetteki ürünler silinecektir.',
};

export default catalog;
