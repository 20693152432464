const errors = {
    error: 'Hata: {{message}}',
    accessError: 'uygulamanın kameraya erişmesi gerekiyor',
    addReturnedBottle: 'İade ambalajı eklenirken hata oluştu',
    reduceReturnedBottle: 'İade edilen ambalaj eklenirken hata oluştu',
    addressExists: 'Ekleme hatası. Bu adres zaten var',
    assignInterval: 'Seçilen aralık atanamadı',
    assignAddress: 'Seçilen adres atanamadı',
    assignPromocode: 'Promosyon kodu uygulanamadı',
    fetchProductSets: 'Ürün setleri alınırken hata oluştu',
    reduceProductSet: 'Sepetteki ürün setlerinin sayısını azaltırken hata oluştu',
    addProductSet: 'Sepete bir dizi ürün eklerken hata oluştu',
    changeProductQuantity: 'Sepetteki ürün miktarını değiştirirken hata oluştu',
    changeProductSetQuantity: 'Sepetteki ürün setlerinin sayısını değiştirirken hata oluştu',
    createAddress: 'Adres oluşturulamadı',
    createAnonAddress: 'Anonim adres oluşturulamadı',
};

export default errors;
