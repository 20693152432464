import * as React from "react";
import classNames from "classnames";

export type ViewProps = React.PropsWithChildren<Partial<{
    flexDirection: "row" | "column" | "row-reverse" | "column-reverse",
    alignItems: "flex-start" | "center" | "flex-end",
    justifyContent: "flex-start" | "center" | "flex-end" | "space-between",
    flex: number
    center: boolean,
    inline?: boolean,
    className?: string,
    id?: string,
    style?: React.CSSProperties,
    wrap?: boolean,
}>>

const View: React.FC<ViewProps> = React.forwardRef<HTMLDivElement, ViewProps>(({ children, center, inline, className, id, wrap, ...other }, ref) => {
    const centerStyles = center ? {
        alignItems: "center",
        justifyContent: "center",
    } : {}

    const inlineStyles = inline ? { display: "flex-inline" } : {}

    const flexWrap = wrap ? { flexWrap: 'wrap' } as React.CSSProperties : {};

    return (
        <div
            className={classNames("view", {[className || ""]: !!className})}
            style={{
                ...centerStyles,
                ...other,
                ...inlineStyles,
                ...other.style,
                ...flexWrap,
            }}
            id={id}
            ref={ref}
        >
            {children}
        </div>
    )
});

export default View