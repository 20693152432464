export enum LayoutActionTypes {
    LAYOUT_SET_HEADER_HIDDEN = 'LAYOUT@SET:HEADER:HIDDEN',
    LAYOUT_SET_FOOTER_HIDDEN = 'LAYOUT@SET:FOOTER:HIDDEN',
    LAYOUT_SET_BODY_NO_SCROLL = 'LAYOUT@SET:BODY:NO:SCROLL,',
}

type TLayoutType = {
    setHeaderHidden: (hidden: boolean) => TFooterActions;
    setFooterHidden: (hidden: boolean) => TFooterActions;
    setBodyNoScroll: (noScroll: boolean) => TBodyActions;
};

type TBodyActions = {
    type: LayoutActionTypes;
    payload: { noScroll: boolean };
};

type TFooterActions = {
    type: LayoutActionTypes;
    payload: { hidden: boolean };
};

export type TLayoutActions = TBodyActions & TFooterActions;

export const LayoutActions: TLayoutType = {
    setHeaderHidden(hidden) {
        return {
            type: LayoutActionTypes.LAYOUT_SET_HEADER_HIDDEN,
            payload: { hidden },
        };
    },

    setFooterHidden(hidden) {
        return {
            type: LayoutActionTypes.LAYOUT_SET_FOOTER_HIDDEN,
            payload: { hidden },
        };
    },

    setBodyNoScroll(noScroll) {
        document.body.style.overflowY = noScroll ? 'hidden' : 'auto';

        return {
            type: LayoutActionTypes.LAYOUT_SET_BODY_NO_SCROLL,
            payload: { noScroll },
        };
    },
};
