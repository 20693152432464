import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';

import AuthService from './AuthService';
import I18n from './i18n';

export type Source = 'admin' | 'partner_cabinet';

type AuthorizeApiOptions = {
    headerKey?: string;
    prefix?: string;
    bearer?: boolean;
};

export type TFetch = {
    fetch: AxiosInstance;
    authorizeApi: (token?: string) => void;
    setBaseUrl: (baseURL: string) => void;
    logout: (opt?: AuthorizeApiOptions) => void;
    setLocale: (locale: string | null) => void;
    enrichToken: () => Promise<AxiosResponse<any> | void>;
};

type ApiConfig = {
    response?: AxiosResponse<any>;
    defaultGetParams?: { [x: string]: string | number };
    enrichTokenUrl?: string;
};

const config: { source?: Source; version?: string } = {};

export const setConfig = (source?: Source, version?: string) => {
    config.source = source;
    config.version = version;
};

const APIs: { [x: string]: { fetch: AxiosInstance } } = {};

export const authorizeApi = (name: string) => (token?: string) => {
    setHeaders(name)(AuthService.signHeaders({}, token));
};

const setHeaders = (name: string) => (headers: Record<string, string | null>) => {
    APIs[name].fetch.defaults.headers.common = {
        ...APIs[name].fetch.defaults.headers.common,
        ...headers,
    };
};

const setBaseUrl = (name: string) => (baseURL: string) => {
    APIs[name].fetch.defaults.baseURL = baseURL;
};

const logout = (name: string) => () => {
    setHeaders(name)({ [AuthService.authHeaderKey]: null });
};

async function repeatRequest(name: string, response: AxiosResponse<any>) {
    return await APIs[name]
        .fetch({
            url: response.config.url,
            data: response.config.data,
            method: response.config.method,
        })
        .then(result => {
            return Promise.resolve(result);
        })
        .catch(e => {
            return Promise.reject(e);
        });
}

async function enrichToken(name: string, { enrichTokenUrl, response = undefined }: ApiConfig = {}) {
    return AuthService.enrichToken(enrichTokenUrl!)
        .then(async token => {
            authorizeApi(name)(token);

            if (response) {
                return await repeatRequest(name, response);
            } else {
                return Promise.resolve();
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

const refreshToken = async (name: string, response: AxiosResponse<any>) => {
    return AuthService.refreshToken()
        .then(async token => {
            authorizeApi(name)(token);

            return await APIs[name]
                .fetch({
                    url: response.config.url,
                    data: response.config.data,
                    method: response.config.method,
                })
                .then(result => {
                    return Promise.resolve(result);
                })
                .catch(() => {
                    AuthService.logout();
                    return Promise.reject(response);
                });
        })
        .catch(error => {
            AuthService.logout();
            return Promise.reject(error);
        });
};

const isUnauthorizedOnTokenCreation = (error: AxiosError<any>) => {
    return (
        error.response &&
        error.response.status === 401 &&
        error.config &&
        error.config.url &&
        !~error.config.url.indexOf('/token/get')
    );
};

function needRefresh(config: AxiosError<any>['config'], response: AxiosResponse<any> | undefined) {
    return (
        response &&
        response.status === 401 &&
        response.data &&
        config &&
        config.headers &&
        config.headers.Authorization
    );
}

function needEnrich(config: AxiosError<any>['config'], response: AxiosResponse<any> | undefined) {
    return (
        response &&
        response.status === 424 &&
        response.data &&
        config &&
        config.headers &&
        config.headers.Authorization
    );
}

function createApi(name: string, { defaultGetParams, enrichTokenUrl }: ApiConfig = {}) {
    const fetch = axios.create({
        responseType: 'json',
        timeout: 60000,
        headers: {
            Pragma: 'no-cache',
            Expires: 0,
            'content-type': 'application/json',
            Source: config.source,
            'version-app': config.version,
        },
        validateStatus: status => status < 400,
    });

    fetch.interceptors.request.use(
        async config => {
            let conf = config;

            if (defaultGetParams) {
                if (!conf.params) {
                    conf.params = {};
                }
                conf.params = defaultGetParams;
            }

            let headers = config.headers;

            if (!headers.common['Accept-Language']) {
                headers = { ...headers, 'Accept-Language': I18n.locale };
            }

            const authorizationToken = APIs[name].fetch.defaults.headers?.common.Authorization;
            if (authorizationToken) {
                headers.common.Authorization = authorizationToken;
            }

            return {
                ...config,
                headers,
            };
        },
        error => {
            return Promise.reject(error);
        },
    );

    fetch.interceptors.response.use(
        async response => response,
        async (error: AxiosError<any>) => {
            if (needEnrich(error.config, error.response)) {
                return await enrichToken(name, {
                    response: error.response,
                    enrichTokenUrl,
                });
            }

            if (needRefresh(error.config, error.response)) {
                return await refreshToken(name, error.response!);
            } else if (isUnauthorizedOnTokenCreation(error)) {
                AuthService.logout();
                return Promise.reject(error);
            }

            return Promise.reject(error);
        },
    );

    const api: TFetch = {
        fetch,
        authorizeApi: authorizeApi(name),
        setBaseUrl: setBaseUrl(name),
        logout: logout(name),
        setLocale: locale => setHeaders(name)({ 'Accept-Language': locale }),
        enrichToken: async () => enrichToken(name, { enrichTokenUrl }),
    };

    APIs[name] = api;

    return api;
}

export default createApi;

export function getErrorMessage(e: AxiosError<{ message: string }>): string {
    return e.isAxiosError && e.response ? e.response.data.message : e.message;
}

export function logoutAPI() {
    Object.keys(APIs).forEach(logout);
}
