import az from './az';
import en from './en';
import ru from './ru';
import tr from './tr';
import { createTranslationMap } from '../aqua-delivery-web-client-ui/i18n';

export const translateMap: typeof ru = createTranslationMap(ru);

const langs = {
    ru,
    en,
    tr,
    az,
};

export default langs;
