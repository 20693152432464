const login = {
    title: 'Giriş / Qeydiyyat',
    titleBonus: 'Sifariş vermək və bonuslar almaq üçün daxil olun',
    content: {
        termsOfUse: {
            text: 'Davam etməklə mən',
            link: 'məxfilik siyasəti ilə razıyam',
        },
        phoneNumber: {
            article:
                'Sifariş vermək, bonuslar almaq və daha çox şey üçün daxil olun və ya qeydiyyatdan keçin',
        },
        smsCode: {
            smsText: 'Göstərilən nömrəyə gələn SMS kodunu daxil edin',
            phoneCallText: 'Zəng edən nömrənin son {{codeLength}} rəqəmini daxil edin',
            phoneCallTextSubtitle:
                'saniyə ərzində zəng gəlməyibsə, sizə avtomatik {delayTime}} olaraq SMS ilə icazə kodu göndəriləcək',
            emailText: 'Göstərilən ünvana göndərilən kodu daxil edin',
            codeDidNotCome: 'Kod gəlmədi?',
            resendCode: 'Yenidən göndərin',
            retryCall: 'Yenidən zəng tələb edin',
            timeLeft: ' san. {value}} sonra',
        },
        emailCode: {
            text: 'Göstərilən poçta alınan kodu daxil edin',
            codeDidNotCome: 'Kod gəlmədi? {{value}} saniyədən sonra yenidən göndərin',
        },
        email: {
            text: 'E-poçtu daxil edin',
        },
    },
    form: {
        fields: {
            username: {
                placeholder: 'example@mail.com',
                label: 'E-mail',
                validations: {
                    require: 'Sahə doldurulmalıdır',
                    email: 'Siz yanlış e-poçt daxil etmisiniz',
                },
            },
            password: {
                label: 'parol',
                validations: {
                    require: 'Sahə doldurulmalıdır',
                },
            },
            rememberMe: {
                label: 'Məni yaddaşa sal',
            },
            phoneNumber: {
                label: 'Telefon',
                validations: {
                    phone: 'Siz yanlış telefon nömrəsi daxil etmisiniz',
                    require: 'Sahə doldurulmalıdır',
                },
            },
            email: {
                label: 'e-poçt ',
            },
            firstName: {
                label: 'Ad və soyad',
            },
        },
    },
    links: {
        registration: 'Qeydiyyat',
        recoveryPassword: 'parolumu unutmuşam :(',
    },
    profileButton: 'Kabinet',
    loginButton: 'Giriş / Qeydiyyat',
    logoutButton: 'Çıxış',
    submitButton: {
        next: 'Sonrakı',
        confirm: 'Təsdiq edin',
    },
    error: 'Avtorizasiya xətası baş verdi. Lütfən, sonra yenidən cəhd edin.',
};

export default login;
