import { message } from 'antd';
import Axios from 'axios';

import { batch } from 'react-redux';

import { TFetchResult } from './../../types';
import { CartActions } from './cart';
import { OrderActions } from './order';
import { t } from '../../aqua-delivery-web-client-ui/i18n';

import { translateMap } from '../../translations';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { StorageKeys } from '../../utils/enums/StorageKeys';
import TokenHelper from '../../utils/token';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';

export const Types = {
    SET_ADDRESS: 'ADDRESSES@SET:ADDRESSES',
    SET_SELECTED_ADDRESS: 'ADDRESSES@SET:SELECTED:ADDRESS',
    SET_NETWORK_STATUS: 'ADDRESSES@SET:NETWORK:STATUS',
};

export type TSetAddresses = {
    type: typeof Types.SET_ADDRESS;
    payload: TAddress[];
};

export type TSetSelectedAddress = {
    type: typeof Types.SET_ADDRESS;
    payload: any;
};

export type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TAddressesActions = TSetAddresses;

export type TAddress = {
    building: string;
    city: string;
    city_id: number;
    comment: string | null;
    coordinates: {
        latitude: string;
        longitude: string;
    };
    district: string | null;
    district_id: number;
    entrance: string;
    external_id: string | null;
    floor: string;
    hasOrganization: boolean;
    house: string;
    housing: string;
    id: number | null;
    latitude: string;
    longitude: string;
    loyalty_system: number | boolean;
    name: string | null;
    note?: string | null;
    room: string | null;
    street: string;
    user_id: number | null;
    uuid: string;
    valid_for_delivery: boolean;
    legal_person_id: string;
};

type TAddressResponse = {
    data: TAddress[];
};

export type TAddressAssign = {
    cart: string;
    latitude: number;
    longitude: number;
    addressId?: string | null;
    name?: string;
    city?: string;
    street?: string;
    building?: string;
    room?: string;
    note?: string;
};

export type TAddressCreate = {
    district?: string | null;
    house: string;
    name?: string | null;
    street: string;
    room?: string | null;
    housing?: string | null;
    building?: string | null;
    entrance?: string | null;
    floor?: string | null;
    comment?: string | null;
    latitude: string;
    longitude: string;
    place_id?: number;
};

export type TAddressAdditionalInfo = {
    entrance?: string | null;
    floor?: string | null;
    room?: string | null;
};

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    if (error.response && error.response.status !== 404) {
        const errorMessage = error?.message
            ? `${defaultErrorMessage}. ${error?.message}`
            : defaultErrorMessage;
        message.error(errorMessage);
    }
};

type AddressesActionsType = {
    fetchAddresses: () => TAction<Promise<void>>;
    restoreAddresses: () => TAction<Promise<void>>;
    clearAddresses: () => TAction<Promise<void>>;
    createAddress: (address: TAddressCreate) => TAction<Promise<void>>;
    createAnonymousAddress: (address: TAddressCreate) => TAction<Promise<void>>;
    selectAddress: (address: TAddress | null) => TAction<Promise<void>>;
    addAnonymousAddressAfterLogin: () => TAction<Promise<void>>;
    assignAddress: (address: TAddress) => TAction<Promise<void>>;
    addAddress: (addresses: TAddress) => TAction<Promise<void>>;
    deleteAddress: (addressId: number) => TAction<Promise<void>>;
    deleteAnonymousAddress: (addressUUID: string) => TAction<Promise<void>>;
    restoreSelectedAddress: () => TAction<Promise<void>>;
    addAdditionalAddressInfo: (data: TAddressAdditionalInfo, id: number) => TAction<Promise<void>>;
    setAddresses: (addresses: TAddress[]) => TSetAddresses;
    setSelectedAddress: (address: TAddress | null) => TSetSelectedAddress;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
};

const tMap = translateMap.errors;

export const AddressesActions: AddressesActionsType = {
    fetchAddresses() {
        return async (dispatch, _, { api, httpClientServices }) => {
            const sort = '-created_at';
            const searchParams = '?' + new URLSearchParams({ sort }).toString();

            const anonymousAddresses = localStorage.getItem('anonymousAddresses');
            if (anonymousAddresses) {
                dispatch(this.clearAddresses());
            }

            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            await httpClientServices
                .getClient()
                .get<TAddressResponse>(api.getAddresses + searchParams)
                .then(({ data }) => {
                    batch(() => {
                        dispatch(this.setNetworkStatus(NetworkStatus.ready));
                        if (data.data.length > 0) {
                            batch(() => {
                                dispatch(this.setAddresses(data.data));
                                dispatch(this.restoreSelectedAddress());
                            });
                        }
                    });
                })
                .catch(e => {
                    dispatch(this.setNetworkStatus(NetworkStatus.ready));
                    errorHandler(e, 'Failed to fetch addresses');
                });

            if (anonymousAddresses) {
                await dispatch(this.addAnonymousAddressAfterLogin());
            }
        };
    },
    restoreAddresses() {
        return async dispatch => {
            const existedAddresses = localStorage.getItem('anonymousAddresses');

            const anonymousAddresses: TAddress[] = existedAddresses
                ? JSON.parse(existedAddresses)
                : [];

            batch(() => {
                dispatch(this.setAddresses(anonymousAddresses));
                if (anonymousAddresses.length) {
                    dispatch(this.selectAddress(anonymousAddresses[0]));
                }
            });
        };
    },
    clearAddresses() {
        return async dispatch => {
            batch(() => {
                dispatch(this.setSelectedAddress(null));
                dispatch(this.setAddresses([]));
            });
        };
    },
    deleteAnonymousAddress(addressUUID) {
        return async (dispatch, _) => {
            const existedAddresses = localStorage.getItem('anonymousAddresses');
            const anonymousAddresses: TAddress[] | null = existedAddresses
                ? JSON.parse(existedAddresses)
                : null;

            const existedSelectedAddress = localStorage.getItem('anonymousSelectedAddresses');
            const anonymousSelectedAddresses: TAddress | null = existedSelectedAddress
                ? JSON.parse(existedSelectedAddress)
                : null;

            const addresses =
                anonymousAddresses &&
                anonymousAddresses.filter(item => {
                    return item.uuid !== addressUUID;
                });

            let selectedAddress: TAddress | null = null;

            if (addresses && anonymousSelectedAddresses?.uuid === addressUUID) {
                selectedAddress = addresses[0];
            }

            batch(() => {
                if (addresses && addresses.length !== 0) {
                    if (selectedAddress) {
                        dispatch(this.selectAddress(selectedAddress));
                        localStorage.setItem(
                            'anonymousSelectedAddresses',
                            JSON.stringify(selectedAddress),
                        );
                    }
                    dispatch(this.setAddresses(addresses));
                    localStorage.setItem('anonymousAddresses', JSON.stringify(addresses));
                } else {
                    dispatch(this.setSelectedAddress(null));
                    dispatch(this.setAddresses([]));
                    localStorage.removeItem('anonymousAddresses');
                    localStorage.removeItem('anonymousSelectedAddresses');
                }
            });
        };
    },
    createAddress(address) {
        return async (dispatch, getState, { api, httpClientServices }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            await httpClientServices
                .getClient()
                .post(api.createAddress, address)
                .then(({ data }) => {
                    if (data.success === false) {
                        const errorMessage = data.message
                            ? `${t(tMap.createAddress)}. ${data.message}`
                            : t(tMap.createAddress);
                        message.warning(errorMessage);

                        return;
                    }

                    const { addresses } = getState().addresses;
                    if (addresses) {
                        addresses.unshift(data.data);
                    }

                    batch(() => {
                        if (addresses) {
                            dispatch(this.setAddresses(addresses));
                            dispatch(this.selectAddress(addresses[0]));
                        } else {
                            dispatch(this.setAddresses([data.data]));
                            dispatch(this.selectAddress(data.data));
                        }
                    });
                })
                .catch(e => errorHandler(e, t(tMap.createAddress)))
                .finally(() => dispatch(this.setNetworkStatus(NetworkStatus.ready)));
        };
    },
    createAnonymousAddress(address) {
        return async (dispatch, _, { api, httpClientServices }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            await httpClientServices
                .getClient()
                .post<TFetchResult<TAddress>>(api.createAnonymousAddress, address)
                .then(({ data }) => {
                    if (data.success === false) {
                        const errorMessage = data.message
                            ? `${t(tMap.createAnonAddress)}. ${data.message}`
                            : t(tMap.createAnonAddress);
                        message.warning(errorMessage);

                        return;
                    }

                    const newAnonymousAddress = data.data;
                    const existedAddresses = localStorage.getItem('anonymousAddresses');

                    const anonymousAddresses: TAddress[] = existedAddresses
                        ? JSON.parse(existedAddresses)
                        : [];

                    const isAddressExists = anonymousAddresses.find(item => {
                        if (
                            item.city === data.data.city &&
                            item.district === data.data.district &&
                            item.street === data.data.street &&
                            item.house === data.data.house &&
                            item.room === data.data.room &&
                            item.entrance === data.data.entrance
                        ) {
                            message.warning(t(tMap.addressExists));

                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (!isAddressExists) {
                        anonymousAddresses.unshift(newAnonymousAddress);
                        localStorage.setItem(
                            'anonymousAddresses',
                            JSON.stringify(anonymousAddresses),
                        );
                        localStorage.setItem(
                            'anonymousSelectedAddresses',
                            JSON.stringify(newAnonymousAddress),
                        );

                        batch(() => {
                            dispatch(this.setAddresses(anonymousAddresses));
                            dispatch(this.selectAddress(newAnonymousAddress));
                        });
                    }
                })
                .catch(e => errorHandler(e, t(tMap.createAnonAddress)))
                .finally(() => dispatch(this.setNetworkStatus(NetworkStatus.ready)));
        };
    },
    selectAddress(address) {
        return async (dispatch, getState) => {
            const { info } = getState().cart;
            const { selectedAddress } = getState().addresses;
            if (info?.cart && address) {
                dispatch(this.assignAddress(address)).then(() => {
                    if (address.district_id !== selectedAddress?.district_id) {
                        dispatch(CartActions.calculate());
                    }
                });
            } else {
                dispatch(this.setSelectedAddress(address));
            }
        };
    },
    addAnonymousAddressAfterLogin() {
        return async (dispatch, getState) => {
            const existedAddresses = localStorage.getItem(StorageKeys.anonAddresses);
            const anonymousAddresses: TAddress[] | null = existedAddresses
                ? JSON.parse(existedAddresses)
                : null;

            const existedSelectedAddress = localStorage.getItem(StorageKeys.anonSelectedAddress);
            const anonymousSelectedAddresses: TAddress | null = existedSelectedAddress
                ? JSON.parse(existedSelectedAddress)
                : null;

            if (anonymousAddresses) {
                localStorage.removeItem(StorageKeys.anonAddresses);
                for (let itemAddress of anonymousAddresses) {
                    const tempAddress: TAddressCreate = {
                        house: itemAddress.house,
                        street: itemAddress.street,
                        latitude: itemAddress.latitude,
                        longitude: itemAddress.longitude,
                        district: itemAddress.district,
                        name: itemAddress.name,
                        room: itemAddress.room,
                        housing: itemAddress.housing,
                        building: itemAddress.building,
                        entrance: itemAddress.entrance,
                        floor: itemAddress.floor,
                        comment: itemAddress.comment,
                    };
                    await dispatch(AddressesActions.createAddress(tempAddress));
                }

                const { addresses } = getState().addresses;

                if (addresses && anonymousSelectedAddresses) {
                    const address = addresses?.find(address => {
                        return (
                            address.latitude === anonymousSelectedAddresses.latitude &&
                            address.longitude === anonymousSelectedAddresses.longitude
                        );
                    });

                    if (address) {
                        await dispatch(AddressesActions.selectAddress(address));
                        localStorage.removeItem('anonymousSelectedAddresses');
                    }
                }
            }
        };
    },
    assignAddress(address) {
        return async (dispatch, getState, { services, httpClientServices }) => {
            const { info } = getState().cart;
            const { addresses } = getState().addresses;
            const cart = info?.cart;

            if (cart) {
                const isAuth = getState().user.data !== null && TokenHelper.isAnon();
                const data: TAddressAssign = {
                    cart,
                    latitude: +address.coordinates.latitude,
                    longitude: +address.coordinates.longitude,
                };

                if (isAuth) {
                    data['addressId'] = address.external_id;
                } else {
                    data['name'] =
                        address.name || `${address.district}, ${address.street}, ${address.house}`;
                    data['city'] = address.city;
                    data['street'] = address.street;
                    data['building'] = address.building || address.house;
                    data['room'] = address.room || '';
                    data['note'] = address.note || '';
                }

                await httpClientServices
                    .getClient('cart')
                    .post(services.cart.assignAddress, data)
                    .then(() => {
                        const selectedAddress = addresses?.find(obj => obj.uuid === address.uuid);
                        if (selectedAddress) {
                            batch(() => {
                                dispatch(OrderActions.setReadyField(CheckoutFields.address, true));
                                dispatch(
                                    OrderActions.setDefaultField(
                                        CheckoutFields.address,
                                        selectedAddress,
                                    ),
                                );
                                dispatch(this.setSelectedAddress(selectedAddress));
                            });
                        }
                    })
                    .catch(e => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.address, false));
                        errorHandler(e, t(tMap.assignAddress));
                    });
            }

            return;
        };
    },
    addAddress(address) {
        return async (dispatch, getState) => {
            const { addresses } = getState().addresses;
            dispatch(this.setAddresses(addresses ? [...addresses, address] : [address]));
        };
    },

    deleteAddress(addressId) {
        return async (dispatch, getState, { api, httpClientServices }) => {
            const { addresses } = getState().addresses;
            await httpClientServices
                .getClient()
                .delete<TFetchResult<TAddress>>(api.deleteAddress, { data: { id: addressId } })
                .then(() => {
                    if (addresses?.length === 1) {
                        dispatch(this.setAddresses([]));
                    } else {
                        dispatch(this.fetchAddresses());
                    }
                })
                .catch(e => errorHandler(e, 'Failed to delete address'));
        };
    },
    restoreSelectedAddress() {
        return async (dispatch, getState) => {
            const addressUUID =
                localStorage.getItem(StorageKeys.selectedAddress) ??
                localStorage.getItem(StorageKeys.deprecatedSelectedAddress);
            const { addresses } = getState().addresses;
            const selectedAddress = addresses?.find(address => address.uuid === addressUUID);
            if (selectedAddress) {
                dispatch(this.selectAddress(selectedAddress));
            } else {
                if (addresses) {
                    dispatch(this.selectAddress(addresses[0]));
                }
            }
        };
    },
    addAdditionalAddressInfo(data, id) {
        return async (_, __, { httpClientServices }) => {
            const url = `/v2/user-addresses/${id}/additional-params`;
            httpClientServices
                .getClient()
                .patch(url, data)
                .catch(e => errorHandler(e, 'Failed to add additional parameters to address'));
        };
    },
    setSelectedAddress(address) {
        const selectedAddress =
            localStorage.getItem(StorageKeys.selectedAddress) ??
            localStorage.getItem(StorageKeys.deprecatedSelectedAddress);
        if (address && selectedAddress !== address.uuid) {
            localStorage.setItem(StorageKeys.selectedAddress, address.uuid);
        }
        return {
            type: Types.SET_SELECTED_ADDRESS,
            payload: address,
        };
    },
    setAddresses(addresses) {
        return {
            type: Types.SET_ADDRESS,
            payload: addresses,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
