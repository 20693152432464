const cart = {
    product: {
        title: 'Səbət',
        relatedTitle: 'Bu məhsulla adətən alınır',
        cartEmpty: ' Səbət boşdur :(',
        backToCatalog: 'Alış-verişə',
        next: 'növbəti',
        autoProduct: 'Avtomatik əlavə edildi ',
        returnedBottles: {
            title: 'Qaytarma qabı',
            buttons: {
                addBottles: 'Qaytarma qabı əlavə edin ',
                skipBottles: 'qabsız davam edin',
            },
        },
        deletedProduct: 'Məhsul silindi',
        restoreProductBtn: 'Bərpa edin',
    },
    order: {
        title: 'Çatdırılma',
        payment: 'Onlayn ödəniş',
        addAddress: {
            title: 'Çatdırılma ünvanını göstərin',
            subtitle: 'Kuryerin sizi tapa bilməsi üçün',
            btnOpen: 'Ünvanı əlavə edin',
            loading: 'Ünvan yükləmə',
            back: 'Geri',
            noFoundAdress: 'Doğru ünvanı tapmadım',
            manuallyLocation: 'Xəritədə sifarişin çatdırılmasını istədiyiniz dəqiq yeri göstərin',
            mainLocation: 'Yaşayış məntəqəsi:',
            secondaryLocation: 'Küçə / rayon:',
            coordinates: 'Koordinatlar:',
            btnConfirm: 'Təsdiq edin',
            btnNext: 'Sonrakı',
            fullAddress: 'Tam ünvanı göstərin',
            street: 'küçə',
            house: 'Ev',
            entrance: 'Giriş',
            floor: 'Mərtəbə',
            room: 'Mənzil / Ofis',
            name: 'Adı, məsələn Ev və ya Ofis',
            noRoomNumber: 'Mənzilin  nömrəsi yoxdur',
            comment: 'Şərh',
            errorLocationSearch: 'Təəssüf ki, ünvanınız tapılmadı',
            placeholder: 'Şəhəri, küçəni və evi daxil edin',
            notFound: 'Heç nə tapılmadı',
        },
        addressField: {
            title: 'Çatdırılma ünvanı',
            noAddresses: 'Ünvan yoxdur',
            addAddress: 'ünvanı göstərin',
            changeAddress: 'Başqa bir ünvan göstərin',
            city: 'şəh.',
            street: 'küç.',
            house: 'ev.',
            entrance: 'gir.',
            floor: 'mər.',
            room: 'mən.',
        },
        deliveryTime: {
            title: 'Çatdırılma vaxtı',
            loading: 'Yükləmə intervalları',
            noTimeForAdress: 'Göstərilən ünvan üçün əlçatan vaxt yoxdur',
        },
        bonusField: {
            title: 'Bonus xalları',
            useBonuses: 'Bonus xallarından istifadə edin',
            notSufficientBonuses: 'Sifarişi ödəmək üçün kifayət qədər bonus xalı yoxdur',
            firstOrder: 'Bonus qazanmağa başlamaq üçün sifariş verin.',
            payExtra: 'Əlavə ödəniş etməli olacaqsınız: {{value}}',

            more: 'Daha ətraflı',
        },
        phoneNumber: 'Telefon nömrəsi',
        enterPhoneNumber: 'Telefon nömrənizi daxil edin',
        notValidPhoneNumber: 'Zəhmət olmasa düzgün telefon nömrəsi daxil edin',
        orderCost: 'Sifariş və qiymət',
        orderSum: 'Sifariş məbləği: ',
        deliveryCost: 'Çatdırılma: ',
        paymentMethod: 'Ödəniş üsulu',
        paymentLoading: 'Ödəniş üsulları yüklənir',
        errorPaymentLoading: 'Ödəniş növlərinin alınması xətası',
        promocodeTitle: 'Promo kodu daxil edin ',
        promocode: 'Promo kod',
        apply: 'Tətbiq edin',
        comment: 'Sifariş şərhi',
        pc: 'əd.',
        addressNotSelected: 'Çatdırılma ünvanı seçilməyib',
    },
    footer: {
        returnedBottles: 'Geri qaytarılacaq qablar',
        helpMessageOk: 'Tamam, aydındır',
        infoMessage: 'Geri qaytarılan boş qablar, sifariş sayından çox ola bilməz.',
        sum: 'Sifariş məbləği: ',
        bonuses: 'xallar',
        shortBonuses: ' b',
        delivery: 'Çatdırılma: ',
        сheckout: 'Sifariş verin',
        total: 'Ödəniş üçün: ',
    },
};

export default cart;
