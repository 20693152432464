import { TReturnedBottles, Types } from '../actions/returnedBottles';

export declare type ReturnedBottles = {
    quantity: number;
    isUserTriggeredContainerBlock: boolean;
    isUserTriggeredReturnedBottles: boolean;
    helpModalContent: string | null;
};

const initState: ReturnedBottles = {
    quantity: 0,
    isUserTriggeredContainerBlock: false,
    isUserTriggeredReturnedBottles: false,
    helpModalContent: null,
};

const returnedBottles = (state = initState, action: TReturnedBottles): ReturnedBottles => {
    let newState;
    switch (action.type) {
        case Types.SET_BOTTLE_QUANTITY:
            newState = { ...state, quantity: action.payload };
            break;
        case Types.SET_IS_USER_TRIGGERED_CONTAINER_BLOCK:
            newState = { ...state, isUserTriggeredContainerBlock: action.payload };
            break;
        case Types.SET_IS_USER_TRIGGERED_RETURNED_BOTTLES:
            newState = { ...state, isUserTriggeredReturnedBottles: action.payload };
            break;
        case Types.SET_HELP_MODAL_CONTENT:
            newState = { ...state, helpModalContent: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default returnedBottles;
