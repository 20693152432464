import React from 'react';

import ReactDOM from 'react-dom';

import App, { AppLoader } from './App';
import { initConfig } from './config';
import * as serviceWorker from './serviceWorker';
import { fetchAppConfig, getDomain } from './utils/appConfig';
import StorageHelper from './utils/StorageHelper';

import './index.css';

export const __DEV__ = window.location.hostname === 'localhost';

const checkIfDevEnvironmentChanged = () => {
    if (__DEV__) {
        const localUrl = localStorage.getItem('serverUrl');
        const serverUrl = getDomain();
        if (localUrl) {
            if (localUrl !== serverUrl) {
                StorageHelper.clearLocalStorageWithExceptions();
                localStorage.setItem('serverUrl', serverUrl);
            }
        } else {
            localStorage.setItem('serverUrl', serverUrl);
        }
    }
};

const renderApp = async () => {
    await fetchAppConfig();
    initConfig();
    ReactDOM.render(<App />, document.getElementById('root'));
};

const timer: NodeJS.Timeout = setInterval(() => {
    ReactDOM.render(<AppLoader />, document.getElementById('root'));
    //@ts-ignore
    if (window.env) {
        checkIfDevEnvironmentChanged();
        renderApp();
        clearInterval(timer);
    }
}, 200);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
