import React, { FC } from 'react';

import { Spin } from 'antd';

import { LoaderContext } from './LoaderContext';

type ChildrenWithState = (args: { loading: boolean }) => React.ReactNode;

type LoaderContainerProps = {
    children: React.ReactNode | ChildrenWithState;
    height?: number;
    tip?: string;
};

const LoaderContainer: FC<LoaderContainerProps> = ({ children, height, tip }) => {
    const [loading, setState] = React.useState<Record<string, boolean>>({});

    const setLoaderState = () => {
        let states: Record<string, boolean> = {};

        return (id: string, state: boolean) => {
            states = {
                ...states,
                [id]: state,
            };

            setState(states);
        };
    };

    const showLoader = !!Object.values(loading).find(item => !!item);

    return (
        <LoaderContext.Provider value={{ setLoaderState: setLoaderState() }}>
            <Spin style={{ minHeight: 100, height }} tip={tip} spinning={showLoader} delay={100}>
                {
                    //@ts-ignore
                    typeof children === 'function' && children({ loading: showLoader })
                }
                {typeof children !== 'function' && children}
            </Spin>
        </LoaderContext.Provider>
    );
};

export default LoaderContainer;
