const product = {
    title: '',
    count: 'Количество',
    totalPay: ' Итого к оплате: ',
    addButton: ' Добавить',
    addedButton: 'Добавлено',
    price: 'Цена: ',
    promotion: 'Цена может снижаться, если количество товаров будет больше 1',
    maxCountMessage: 'Достигнуто максимальное количество текущего товара',
    ct: 'шт.',
};

export default product;
