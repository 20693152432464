const ordersHistory = {
    title: 'sipariş geçmişi',
    btn: {
        more: 'devamı',
        repeat: 'Tekrarla',
        toCatalog: 'Kataloğa',
        pay: 'Öde',
    },
    error: 'Maalesef bu siparişi tekrarlamak mümkün değildir',
    noOrders: 'Henüz tek bir sipariş vermediniz :(',
    cancelOrder: {
        title: 'siparişi iptal et',
        details: {
            orderNumber: 'Sipariş No.',
            shippingAddress: 'Teslimat adresi',
            shippingDate: 'Teslim tarihi ve saati',
        },
        leaveComment: 'Yorumunuzu bırakın',
        selectReasonAndNote: 'Lütfen bir iptal nedeni seçin veya bir yorum bırakınn',
        btn: {
            back: 'geri',
            cancel: 'siparişi iptal et',
        },
    },
    orderStatus: {
        new: 'Yeni',
        completed: 'Tamamlanıyordu',
        approved: 'Doğrulanıyordu',
        cancelled: 'Iptal edildi',
        inProcess: 'İşlenmede',
        undelivered: 'Teslim edilmedi',
        requiresConfirmation: 'Onay üzerine',
    },
};

export default ordersHistory;
