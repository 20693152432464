export default {
    months: {
        one: 'month',
        other: 'months',
    },
    days: {
        one: 'day',
        other: 'days',
    },
    hours: {
        one: 'hour',
        other: 'hours',
    },
    minutes: {
        one: 'minute',
        other: 'minutes',
    },
    partnersDative: {
        one: 'partner',
        other: 'partners',
    },
};
