import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import { AddressesActions, TAddressAssign } from './addresses';
import { TCatalogProduct } from './catalog';
import { OrderActions } from './order';
import { TPaymentAssign } from './payment';
import { ReturnedBottles } from './returnedBottles';
import { t } from '../../aqua-delivery-web-client-ui/i18n';
import { TCompany } from '../../entities/CompanyEntity';
import { translateMap } from '../../translations';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import URLHelper from '../../utils/URLHelper';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';

const tMap = translateMap.errors;

export const Types = {
    INIT_CART: 'CART@INIT:CART',
    SET_PRODUCTS: 'CART@SET:PRODUCTS',
    SET_PRODUCT_SETS: 'CART@SET:PRODUCT:SETS',
    SET_DELETED_PRODUCTS: 'CART@SET:DELETED:PRODUCTS',
    SET_CALCULATE: 'CART@SET:CALCULATE',
    SET_CALCULATE_NETWORK_STATUS: 'CART@SET:CALCULATE:NETWORK:STATUS',
    SET_DETAIL_NETWORK_STATUS: 'CART@SET:DETAIL:NETWORK:STATUS',
    SET_INIT_CART_NETWORK_STATUS: 'CART@SET:INIT:CART:NETWORK:STATUS',
};

export type TInitCart = {
    '@context': string;
    '@id': string;
    '@type': string;
    partner?: string;
    cart: string;
    id?: string;
    createdAt?: string;
    cartId?: string;
};

export type TNoteAssign = {
    '@id': string;
    '@type': string;
    note: string;
};

export type TDeletedItem = {
    id: number;
    quantity: number;
    isProductSet: boolean;
};

export type TItemAssign = {
    '@id': string;
    '@type': string;
    product: {
        '@id': string;
        '@type': string;
        externalId: number;
        name: string;
    } | null;
    productSet: {
        '@id': string;
        '@type': string;
        externalId: number;
        name: string;
    } | null;
    quantity: number;
    fromRecommended: boolean;
    type: string;
};

export type TDetailsCart = {
    '@context': string;
    '@id': string;
    '@type': string;
    address: TAddressAssign & {
        '@id': string;
        '@type': string;
    };
    deliveryPeriod: null;
    payment: TPaymentAssign | null;
    user: null;
    returnableContainer: {
        '@id': string;
        '@type': string;
        quantity: number;
    };
    note: TNoteAssign | null;
    callRestriction: null;
    loyaltyPoints: null;
    promotionCode: null;
    items: TItemAssign[];
};

export type TCalculateProduct = {
    '@id': string;
    '@type': string;
    externalId: number;
    name: string;
};

export type TCartCalculate = {
    '@context': string;
    '@id': string;
    '@type': string;
    items: {
        '@type': string;
        '@id': string;
        id: string;
        price: {
            amount: string;
            currency: string;
        };
        quantity: number;
        sum: {
            amount: string;
            currency: string;
        };
        cashback: {
            amount: string;
            currency: string;
        };
        cashbackSum: {
            amount: string;
            currency: string;
        };
        type: string;
        product?: TCalculateProduct;
        productSet?: Omit<TCalculateProduct, 'name'>;
    }[];
    cartSum: {
        amount: string;
        currency: string;
    };
    deliveryCost: {
        amount: string;
        currency: string;
    };
    sum: {
        amount: string;
        currency: string;
    };
    maxCashbackPoints: {
        amount: string;
        currency: string;
    };
    cashbackSum: {
        amount: string;
        currency: string;
    };
    total: {
        amount: string;
        currency: string;
    };
    requiredProducts: {
        '@type': string;
        '@id': string;
        externalProductId: number;
        quantity: number;
        name: string;
        reason: string;
    }[];
    insufficientReturnableContainer?: number;
    returnableContainer?: number;
};

export type AuthenticateType = {
    '@context': string;
    '@id': string;
    '@type': string;
    authenticatedCart: string;
    cart: string;
    id: string;
    createdAt: string;
};

export type TCartItem = {
    productId: number;
    quantity: number;
    cartItem?: string;
    '@context'?: string;
    '@id'?: string;
    '@type'?: string;
    cart?: string;
    createdAt?: string;
    id?: string;
    type?: string;
    isRequired?: boolean;
    tooltipText?: string;
};

export type TInitCartAction = {
    type: typeof Types.INIT_CART;
    payload: TInitCart | null;
};

export type TSetCartItemsAction = {
    type: typeof Types.SET_PRODUCTS;
    payload: Record<number, TCartItem> | null;
};

export type TSetCartItemSetsAction = {
    type: typeof Types.SET_PRODUCT_SETS;
    payload: Record<number, TCartItem> | null;
};

export type TSetDeletedCartItemsAction = {
    type: typeof Types.SET_DELETED_PRODUCTS;
    payload: TDeletedItem[];
};

export type TSetCalculate = {
    type: typeof Types.SET_CALCULATE;
    payload: TCartCalculate | null;
};

export type TSetCalculateNetworkStatus = {
    type: typeof Types.SET_CALCULATE_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TSetDetailNetworkStatus = {
    type: typeof Types.SET_DETAIL_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TSetInitCartNetworkStatus = {
    type: typeof Types.SET_INIT_CART_NETWORK_STATUS;
    payload: NetworkStatus;
};

export enum ContainersToReturn {
    noBottles = 0,
    haveBottles = 1,
}

export type TCartActions =
    | TInitCartAction
    | TSetCartItemsAction
    | TSetDeletedCartItemsAction
    | TSetCalculate
    | TSetDetailNetworkStatus
    | TSetInitCartNetworkStatus
    | TSetCalculateNetworkStatus;

type CartActionsType = {
    restoreCart: () => TAction<Promise<void>>;
    restoreProducts: (
        products: { productId: number; quantity: number }[],
    ) => TAction<Promise<void>>;
    initCart: (defaultCity?: TCompany['defaultCity']) => TAction<Promise<void>>;
    addProductToCart: (
        quantity: number,
        productId: number,
        productName: string,
        type?: string,
        isRequired?: boolean,
        tooltipText?: string,
    ) => TAction<Promise<void>>;
    addProductSetToCart: (
        quantity: number,
        productSetId: number,
        productName: string,
    ) => TAction<Promise<void>>;
    reduceProduct: (quantity: number, productId: number) => TAction<Promise<void>>;
    reduceProductSet: (quantity: number, productSetId: number) => TAction<Promise<void>>;
    removeProduct: (productId: number) => TAction<Promise<void>>;
    removeCartProduct: (
        productId: number,
        quantity: number,
        isProductSet: boolean,
    ) => TAction<Promise<void>>;
    restoreCartProduct: (
        quantity: number,
        productId: number,
        productName: string,
        type?: string,
    ) => TAction<Promise<void>>;
    restoreCartProductSet: (
        quantity: number,
        productId: number,
        productName: string,
    ) => TAction<Promise<void>>;
    calculate: (haveContainersToReturn?: ContainersToReturn) => TAction<Promise<void>>;
    clearCart: () => TAction<Promise<void>>;
    authenticate: () => TAction<Promise<void>>;
    setInfoCart: (payload: TInitCart | null) => TInitCartAction;
    setCartItems: (payload: Record<number, TCartItem> | null) => TSetCartItemsAction;
    setCartItemsSets: (payload: Record<number, TCartItem> | null) => TSetCartItemSetsAction;
    setDeletedCartItems: (payload: TDeletedItem[]) => TSetDeletedCartItemsAction;
    setCalculate: (payload: TCartCalculate | null) => TSetCartItemsAction;
    setCalculateNetworkStatus: (status: NetworkStatus) => TSetCalculateNetworkStatus;
    setDetailNetworkStatus: (status: NetworkStatus) => TSetDetailNetworkStatus;
    setInitCartNetworkStatus: (status: NetworkStatus) => TSetInitCartNetworkStatus;
};

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    if (error.response && error.response.status !== 404) {
        const errorMessage = error?.message
            ? `${defaultErrorMessage}. ${error?.message}`
            : defaultErrorMessage;
        message.error(errorMessage);
    }
};

export const CartActions: CartActionsType = {
    initCart() {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const cart = getState().cart;
            const { addresses, selectedAddress } = getState().addresses;
            const { data: userData } = getState().user;
            if (!userData) {
                localStorage.setItem('isAnonymousCart', 'true');
            }
            if (
                (!localStorage.getItem('cartId') || cart.info === null) &&
                getState().cart.initCartNetworkStatus !== NetworkStatus.loading
            ) {
                try {
                    dispatch(this.setInitCartNetworkStatus(NetworkStatus.loading));
                    const { data } = await httpClientServices
                        .getClient('cart')
                        .post<TInitCart>(services.cart.initCart, {});
                    localStorage.setItem('cartId', data.cart);
                    dispatch(this.setInfoCart(data));
                    if (addresses && addresses.length !== 0) {
                        dispatch(AddressesActions.assignAddress(selectedAddress || addresses[0]));
                    }
                } catch (e) {
                    errorHandler(e, 'Failed to init cart');
                }
                dispatch(this.setInitCartNetworkStatus(NetworkStatus.ready));
            }
        };
    },
    restoreCart() {
        return async (dispatch, _, { services, httpClientServices }) => {
            const cartId = localStorage.getItem('cartId');
            if (cartId) {
                dispatch(this.setDetailNetworkStatus(NetworkStatus.loading));
                httpClientServices
                    .getClient('cart')
                    .get<TDetailsCart>(
                        URLHelper.buildUrl(services.cart.restore, {
                            cartId,
                        }),
                    )
                    .then(({ data }) => {
                        const products = data.items.map(item => ({
                            productId: item.product?.externalId || item.productSet?.externalId,
                            quantity: item.quantity,
                            cartItem: item['@id'],
                        }));
                        batch(() => {
                            dispatch(
                                this.setInfoCart({
                                    '@context': data['@context'],
                                    '@id': data['@id'],
                                    '@type': data['@type'],
                                    cart: cartId,
                                }),
                            );
                            if (products) {
                                //@ts-ignore
                                dispatch(this.restoreProducts(products));
                                dispatch(this.calculate());
                                dispatch(
                                    ReturnedBottles.setQuantity(
                                        data.returnableContainer?.quantity ?? 0,
                                    ),
                                );
                                dispatch(
                                    OrderActions.setDefaultField(
                                        CheckoutFields.address,
                                        data.address,
                                    ),
                                );
                                dispatch(
                                    OrderActions.setDefaultField(
                                        CheckoutFields.payment,
                                        data.payment,
                                    ),
                                );
                                dispatch(
                                    OrderActions.setDefaultField(CheckoutFields.note, data.note),
                                );
                            }
                        });
                    })
                    .catch(e => {
                        errorHandler(e, 'Failed to restore cart');
                        localStorage.removeItem('cartId');
                    });
            }
        };
    },
    restoreProducts(products) {
        return async (dispatch, getState) => {
            const { catalog, productSets } = getState().catalog;
            const items = catalog.map(item => item.products).flat();
            const cartItems: Record<number, TCartItem> = {};
            const requiredProducts: Record<string, string> = JSON.parse(
                localStorage.getItem('requiredProducts') ?? '{}',
            );
            const requiredProductsIds = Object.keys(requiredProducts);
            products.forEach(product => {
                const item =
                    items.find(item => item.id === product.productId) ??
                    productSets.find(item => item.id === product.productId);
                const productStrId = `${product.productId}`;
                if (item) {
                    const cartItem = {
                        ...product,
                        //@ts-ignore
                        type: item.type,
                        isRequired: requiredProductsIds.includes(productStrId),
                        tooltipText: requiredProducts[productStrId],
                    };
                    cartItems[product.productId] = cartItem;
                }
            });
            batch(() => {
                if (Object.keys(cartItems).length > 0) {
                    dispatch(this.setCartItems(cartItems));
                }
                dispatch(this.setDetailNetworkStatus(NetworkStatus.ready));
            });
        };
    },
    authenticate() {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const { selectedAddress } = getState().addresses;
            const isAnonymousCart = localStorage.getItem('isAnonymousCart');
            const cartId = localStorage.getItem('cartId');

            if (info && cartId && isAnonymousCart && isAnonymousCart === 'true') {
                let data;

                localStorage.setItem('isAnonymousCart', 'false');

                const anonymousSelectedAddresses = localStorage.getItem(
                    'anonymousSelectedAddresses',
                );

                if (selectedAddress && !anonymousSelectedAddresses) {
                    data = {
                        cart: info.cart,
                        addressId: selectedAddress.external_id,
                    };
                }

                if (data === undefined) {
                    data = {
                        cart: info.cart,
                    };
                }

                await httpClientServices
                    .getClient('cart')
                    .post<AuthenticateType>(services.cart.authenticate, data)
                    .then(({ data }) => {
                        const newCartId = data.authenticatedCart;
                        info.cart = newCartId;
                        localStorage.setItem('cartId', newCartId);
                        localStorage.removeItem('isAnonymousCart');
                        dispatch(this.setInfoCart(info));
                    })
                    .catch(e => {
                        localStorage.setItem('isAnonymousCart', 'true');
                        errorHandler(e, 'Failed to authenticate cart');
                    });
            }
        };
    },
    addProductToCart(quantity, productId, productName, type, isRequired = false, tooltipText) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info, deletedItems } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    quantity,
                    productId,
                    productName,
                    cart,
                };

                await httpClientServices
                    .getClient('cart')
                    .post<TCartItem>(services.cart.addProduct, data)
                    .then(({ data }) => {
                        const { items } = getState().cart;
                        if (!type) {
                            const { catalog } = getState().catalog;
                            const product = catalog
                                .reduce(
                                    (acc: TCatalogProduct[], current) =>
                                        acc.concat(current.products),
                                    [],
                                )
                                .find(product => product.id === productId);

                            type = product?.type;
                        }

                        return batch(async () => {
                            if (deletedItems.length !== 0) {
                                const filtered = deletedItems.filter(
                                    item => item.id !== productId && item.isProductSet !== false,
                                );
                                dispatch(this.setDeletedCartItems(filtered));
                            }
                            const product = {
                                productId: data.productId,
                                quantity: data.quantity,
                                cartItem: data.cartItem,
                                type,
                                isRequired,
                                tooltipText,
                            };
                            if (items) {
                                items[data.productId] = product;
                                await dispatch(this.setCartItems({ ...items }));
                            } else {
                                await dispatch(
                                    this.setCartItems({
                                        [data.productId]: product,
                                    }),
                                );
                            }
                            await dispatch(this.calculate());
                        });
                    })
                    .catch(e => errorHandler(e, 'Failed to add product to cart'));
            }
        };
    },
    addProductSetToCart(quantity, productSetId, productName) {
        return async (dispatch, getState, { httpClientServices }) => {
            const { info, items, deletedItems } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    quantity,
                    productSetId,
                    productName,
                    cart,
                };
                await httpClientServices
                    .getClient('cart')
                    .post('/order/add_cart_item_product_set_events', data)
                    .then(({ data }) => {
                        return batch(async () => {
                            if (deletedItems.length !== 0) {
                                const filtered = deletedItems.filter(
                                    item => item.id !== productSetId && item.isProductSet !== true,
                                );
                                dispatch(this.setDeletedCartItems(filtered));
                            }
                            const product = {
                                productId: data.productSetId,
                                quantity: data.quantity,
                                cartItem: data.cartItem,
                            };
                            if (items) {
                                items[data.productSetId] = product;
                                dispatch(this.setCartItems({ ...items }));
                            } else {
                                dispatch(
                                    this.setCartItems({
                                        [data.productSetId]: product,
                                    }),
                                );
                            }
                            await dispatch(this.calculate());
                        });
                    })
                    .catch(e => errorHandler(e, t(tMap.addProductSet)));
            }
        };
    },
    reduceProduct(quantity, productId) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info, items } = getState().cart;
            const cart = info?.cart;

            if (cart && items && items[productId]) {
                const data = {
                    quantity,
                    productId,
                    cart,
                };

                await httpClientServices
                    .getClient('cart')
                    .post<TCartItem>(services.cart.reduceProduct, data)
                    .then(({ data }) => {
                        const { items } = getState().cart;
                        if (items) {
                            items[data.productId].quantity = data.quantity;

                            return batch(async () => {
                                await dispatch(this.setCartItems({ ...items }));
                                await dispatch(this.calculate());
                            });
                        }
                    })
                    .catch(e => errorHandler(e, 'Failed to reduce product'));
            }
        };
    },
    reduceProductSet(quantity, productSetId) {
        return async (dispatch, getState, { httpClientServices }) => {
            const { info, items } = getState().cart;
            const cart = info?.cart;
            const data = {
                productSetId,
                quantity,
                cart,
            };
            if (cart && items && items[productSetId]) {
                await httpClientServices
                    .getClient('cart')
                    .post('/order/reduce_cart_item_product_set_events', data)
                    .then(({ data }) => {
                        const { items } = getState().cart;
                        if (items) {
                            items[data.productSetId].quantity = data.quantity;

                            return batch(async () => {
                                await dispatch(this.setCartItems({ ...items }));
                                await dispatch(this.calculate());
                            });
                        }
                    })
                    .catch(e => errorHandler(e, t(tMap.reduceProductSet)));
            }
        };
    },
    removeProduct(productId) {
        return async (dispatch, getState, { services, httpClientServices }) => {
            const { info, items } = getState().cart;
            const cart = info?.cart;
            if (cart && items) {
                const product = items[productId];
                let cartItem;
                if (product.cartItem) {
                    cartItem = product.cartItem;
                } else if (getState().cart.calculate?.items) {
                    const calculateProduct = getState().cart.calculate?.items.find(
                        product =>
                            product.product?.externalId === productId ||
                            product.productSet?.externalId === productId,
                    );
                    cartItem = calculateProduct?.id;
                }

                const data = {
                    cart: cart,
                    cartItem,
                };

                await httpClientServices
                    .getClient('cart')
                    .post(services.cart.removeProduct, data)
                    .then(() => {
                        delete items[productId];
                        const isEmptyCart = Object.keys(items).length === 0;
                        return batch(() => {
                            if (!isEmptyCart) {
                                dispatch(this.setCartItems({ ...items }));
                                dispatch(this.calculate());
                            } else {
                                dispatch(this.clearCart());
                            }
                        });
                    })
                    .catch(e => errorHandler(e, 'Failed to remove product'));
            } else {
                dispatch(this.clearCart());
            }
        };
    },
    removeCartProduct(productId, quantity, isProductSet) {
        return async (dispatch, getState) => {
            const { items, deletedItems } = getState().cart;
            if (items) {
                deletedItems.push({
                    id: productId,
                    isProductSet,
                    quantity,
                });
                await dispatch(this.removeProduct(productId)).then(() => {
                    dispatch(this.setDeletedCartItems(deletedItems));
                });
            }
        };
    },
    restoreCartProduct(id, quantity, name, type) {
        return async (dispatch, getState) => {
            const { info } = getState().cart;
            if (info) {
                dispatch(this.addProductToCart(id, quantity, name, type));
            } else {
                dispatch(this.initCart()).then(() =>
                    dispatch(this.addProductToCart(id, quantity, name, type)),
                );
            }
        };
    },
    restoreCartProductSet(id, quantity, name) {
        return async (dispatch, getState) => {
            const { info } = getState().cart;
            if (info) {
                dispatch(this.addProductSetToCart(id, quantity, name));
            } else {
                dispatch(this.initCart()).then(() =>
                    dispatch(this.addProductSetToCart(id, quantity, name)),
                );
            }
        };
    },
    calculate(haveContainersToReturn = ContainersToReturn.haveBottles) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info, items } = getState().cart;
            if (items && Object.keys(items).length === 0) {
                this.setCartItems(null);
            }
            const cart = info?.cart;
            if (cart && items) {
                const data = {
                    cart,
                    haveContainersToReturn: !!haveContainersToReturn,
                };
                dispatch(this.setCalculateNetworkStatus(NetworkStatus.loading));

                return httpClientServices
                    .getClient('cart')
                    .post<TCartCalculate>(services.cart.calculate, data)
                    .then(({ data }) => {
                        return batch(async () => {
                            if (
                                data.returnableContainer !== undefined &&
                                data.insufficientReturnableContainer &&
                                data.insufficientReturnableContainer > 0
                            ) {
                                dispatch(
                                    ReturnedBottles.addReturnedBottle(
                                        data.insufficientReturnableContainer,
                                    ),
                                );
                            }
                            if (data.requiredProducts.length > 0) {
                                data.requiredProducts.forEach(product =>
                                    dispatch(
                                        CartActions.addProductToCart(
                                            product.quantity,
                                            product.externalProductId,
                                            product.name,
                                            undefined,
                                            true,
                                            product.reason,
                                        ),
                                    ),
                                );

                                localStorage.setItem(
                                    'requiredProducts',
                                    JSON.stringify(
                                        Object.fromEntries(
                                            data.requiredProducts.map(product => [
                                                product.externalProductId,
                                                product.reason,
                                            ]),
                                        ),
                                    ),
                                );
                            }
                            dispatch(
                                ReturnedBottles.setQuantity(
                                    data.returnableContainer ??
                                        data.insufficientReturnableContainer ??
                                        0,
                                ),
                            );
                            dispatch(this.setCalculateNetworkStatus(NetworkStatus.ready));
                            dispatch(this.setCalculate(data));
                        });
                    })
                    .catch(e => {
                        if (e.response.status === 410) {
                            dispatch(this.clearCart());
                        }
                    });
            }
        };
    },
    clearCart() {
        return async dispatch => {
            batch(() => {
                localStorage.removeItem('cartId');
                localStorage.removeItem('isAnonymousCart');
                dispatch(this.setCalculate(null));
                dispatch(this.setCartItems(null));
                dispatch(this.setDeletedCartItems([]));
                dispatch(this.setInfoCart(null));
                dispatch(ReturnedBottles.clearBottles());
            });
        };
    },
    setCartItems(payload) {
        return {
            type: Types.SET_PRODUCTS,
            payload,
        };
    },
    setCartItemsSets(payload) {
        return {
            type: Types.SET_PRODUCT_SETS,
            payload,
        };
    },
    setDeletedCartItems(payload) {
        return {
            type: Types.SET_DELETED_PRODUCTS,
            payload,
        };
    },
    setCalculate(payload) {
        return {
            type: Types.SET_CALCULATE,
            payload,
        };
    },
    setInfoCart(payload) {
        return {
            type: Types.INIT_CART,
            payload,
        };
    },
    setCalculateNetworkStatus(status) {
        return {
            type: Types.SET_CALCULATE_NETWORK_STATUS,
            payload: status,
        };
    },
    setDetailNetworkStatus(status) {
        return {
            type: Types.SET_DETAIL_NETWORK_STATUS,
            payload: status,
        };
    },
    setInitCartNetworkStatus(status) {
        return {
            type: Types.SET_INIT_CART_NETWORK_STATUS,
            payload: status,
        };
    },
};
