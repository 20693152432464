import React, { FC, Suspense, SuspenseProps } from 'react';

import AbsoluteFilledLoader from '../AbsoluteFilledLoader';

const SuspenseWithLoader: FC<Partial<SuspenseProps>> = ({ children, ...other }) => {
    return (
        <Suspense fallback={<AbsoluteFilledLoader style={{ position: 'absolute' }} />} {...other}>
            {children}
        </Suspense>
    );
};

export default SuspenseWithLoader;
