import { message } from 'antd';

import TokenHelper from '../../utils/token';
import { TAction } from '../store';

type TEnrichToken = {
    '@context': string;
    '@id': string;
    '@type': string;
    id: number;
    partnerId: string | null;
    token: string;
};

type PartnerTypes = {
    partnerEnrichToken: () => TAction<Promise<string | null>>;
};

export const PartnerActions: PartnerTypes = {
    partnerEnrichToken() {
        return async (_, getState, { httpClientServices, services }) => {
            const { info } = getState().company;

            const partnerId = info?.partnerId ?? localStorage.getItem('partnerId');

            if (partnerId) {
                try {
                    const { data } = await httpClientServices
                        .getClient('partner')
                        .post<TEnrichToken>(services.partner.enrichToken, { partnerId: partnerId });

                    TokenHelper.setTokens({ token: data.token });

                    return data.token;
                } catch (e) {
                    if (e?.response && e?.response?.status !== 404) {
                        message.error(e?.message);
                    }
                    TokenHelper.cleanTokens();

                    return null;
                }
            }

            return null;
        };
    },
};
