import { message } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';

import { __DEV__ } from '..';
import { AppConfig } from '../entities/AppConfigEntity';

export const getDomain = () => {
    const removeSpaces = (str?: string) => str?.replace(/\s+/g, '');

    if (__DEV__) {
        switch (removeSpaces(process.env.REACT_APP_ENV)) {
            case 'demo':
                return 'client.appsol.ru';
            default:
                return 'client.test.appsol.ru';
        }
    } else return window.location.hostname;
};

export const fetchAppConfig = async () => {
    const fetch = () => {
        const origin = 'https://functions.yandexcloud.net';
        const path = 'd4eat880usspg104l6lj';

        const url = new URL(path + '?', origin).toString();

        const searchParams = new URLSearchParams({
            domain: getDomain(),
            version: '1.0.0',
        }).toString();

        const config: AxiosRequestConfig = { timeout: 30000 };

        return axios.get<AppConfig>(url + searchParams, config);
    };

    try {
        const response = await fetch();

        localStorage.setItem('config', JSON.stringify(response.data));
    } catch (e) {
        if (e instanceof Error) {
            const errorMessage = e.message || 'Failed to fetch cloud config';
            message.error(errorMessage);
        }
    }
};

export const getAppConfig: () => AppConfig = () => {
    return JSON.parse(localStorage.getItem('config') || 'null');
};
