export default class JSONHelper {
    static jsonTemplate = /[{[]{1}([,:{}[]0-9.-+Eaeflnr-u \n\r\t]|".*?")+[}]]{1}/gi;

    static isJsonInString(str: string) {
        return JSONHelper.jsonTemplate.test(str);
    }

    static getJsonFromString(str: string) {
        const data = str.match(JSONHelper.jsonTemplate);

        if (!data?.length) {
            return null;
        }

        return JSON.parse(data[0]);
    }
}
