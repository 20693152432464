const cartModal = {
    title: {
        success: 'Sifariş qeyd olundu',
        noSuccess: 'Sifariş verilmir!',
    },
    subTitle: {
        success: 'Göstərilən vaxtda çatdırılmanı gözləyin',
        noSuccess: 'Sifarişi təkrarlayın və ya başqa bir ödəmə üsulu göstərin',
    },
    btnClose: 'Aydındır',
};

export default cartModal;
