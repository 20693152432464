import { message } from 'antd';
import Axios from 'axios';

import { CartActions, ContainersToReturn } from './cart';
import { t } from '../../aqua-delivery-web-client-ui/i18n';

import { translateMap } from '../../translations';
import { TAction } from '../store';

export const Types = {
    SET_BOTTLE_QUANTITY: 'ReturnedBottles@SET:QUANTITY',
    SET_IS_USER_TRIGGERED_CONTAINER_BLOCK: 'ReturnedBottles@SET:IS:USER:TRIGGERED:CONTAINER:BLOCK',
    SET_IS_USER_TRIGGERED_RETURNED_BOTTLES:
        'ReturnedBottles@SET:IS:USER:TRIGGERED:RETURNED:BOTTLES',
    SET_HELP_MODAL_CONTENT: 'ReturnedBottles@SET:HELP:MODAL:CONTENT',
};

export type TSetQuantityAction = {
    type: typeof Types.SET_BOTTLE_QUANTITY;
    payload: number;
};

export type TSetIsUserTriggeredContainerBlockAction = {
    type: typeof Types.SET_IS_USER_TRIGGERED_CONTAINER_BLOCK;
    payload: boolean;
};

export type TSetIsUserTriggeredReturnedBottlesAction = {
    type: typeof Types.SET_IS_USER_TRIGGERED_CONTAINER_BLOCK;
    payload: boolean;
};

export type TSetHelpModalContentAction = {
    type: typeof Types.SET_HELP_MODAL_CONTENT;
    payload: string;
};

type TReturnedBottle = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    createdAt: string;
    id: string;
    quantity: number;
};

export type TReturnedBottles = TSetQuantityAction;

type ReturnedBottlesType = {
    addReturnedBottle: (quantity: number) => TAction<Promise<void>>;
    reduceReturnedBottle: (quantity: number) => TAction<Promise<void>>;
    setIsUserTriggeredContainerBlock: (
        IsUserTriggered: boolean,
    ) => TSetIsUserTriggeredContainerBlockAction;
    setIsUserTriggeredReturnedBottles: (
        IsUserTriggered: boolean,
    ) => TSetIsUserTriggeredReturnedBottlesAction;
    setQuantity: (quantity: number) => TSetQuantityAction;
    clearBottles: () => TAction<Promise<void>>;
    fetchHelpModalContent: () => TAction<Promise<void>>;
    setHelpModalContent: (Content: string) => TSetHelpModalContentAction;
};

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    if (error.response && error.response.status !== 404) {
        const errorMessage = error?.message
            ? `${defaultErrorMessage}. ${error?.message}`
            : defaultErrorMessage;
        message.error(errorMessage);
    }
};

const tMap = translateMap.errors;

export const ReturnedBottles: ReturnedBottlesType = {
    addReturnedBottle(quantity) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart && quantity) {
                const data = {
                    cart,
                    quantity,
                };
                await httpClientServices
                    .getClient('cart')
                    .post<TReturnedBottle>(services.cart.addReturnableContainer, data)
                    .then(({ data }) => {
                        dispatch(CartActions.calculate(ContainersToReturn.noBottles));
                    })
                    .catch(e => {
                        errorHandler(e, t(tMap.addReturnedBottle));
                    });
            }
        };
    },
    reduceReturnedBottle(quantity) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    quantity,
                };
                await httpClientServices
                    .getClient('cart')
                    .post<TReturnedBottle>(services.cart.reduceReturnableContainer, data)
                    .then(({ data }) => {
                        return dispatch(CartActions.calculate(ContainersToReturn.noBottles));
                    })
                    .catch(e => {
                        errorHandler(e, t(tMap.reduceReturnedBottle));
                    });
            }
        };
    },
    fetchHelpModalContent() {
        return async (dispatch, getState) => {
            const url = getState().company.info?.about_returned_package_url;
            if (url) {
                await Axios.get(url)
                    .then(response => {
                        dispatch(this.setHelpModalContent(response.data));
                    })
                    .catch(e => {
                        errorHandler(e, 'error');
                    });
            }
        };
    },
    setQuantity(quantity) {
        return {
            type: Types.SET_BOTTLE_QUANTITY,
            payload: quantity,
        };
    },
    clearBottles() {
        return async dispatch => {
            dispatch(this.setQuantity(0));
        };
    },
    setIsUserTriggeredContainerBlock(IsUserTriggered) {
        return {
            type: Types.SET_IS_USER_TRIGGERED_CONTAINER_BLOCK,
            payload: IsUserTriggered,
        };
    },
    setIsUserTriggeredReturnedBottles(IsUserTriggered) {
        return {
            type: Types.SET_IS_USER_TRIGGERED_CONTAINER_BLOCK,
            payload: IsUserTriggered,
        };
    },
    setHelpModalContent(Content) {
        return {
            type: Types.SET_HELP_MODAL_CONTENT,
            payload: Content,
        };
    },
};
