const profile = {
    title: 'Kabinet',
    myAddress: 'Mənim ünvanlarım',
    historyOrders: 'Sifariş tarixçəsi',
    messages: 'Dəstəklə əlaqə',
    bonuses: 'Sizin bonus xallarınız',
    share: 'Linki paylaşın',
    delivery: 'Çatdırılma və ödəniş',
    aboutCompany: 'Şirkət haqqında',
    downloadApp: 'Proqramı yükləyin',
    signOut: 'Profildən çıxın',
    btnLogoutOk: 'Bəli, çıxın',
    deleteAccount: 'Hesabı silin',
    modalDeleteAccount: {
        title: 'Hesab silinsin?',
        btnDelete: 'Bəli',
        btnClose: 'Xeyir',
    },
    messageSignOut:
        'Profilinizdən çıxdığınız zaman bütün məhsullar səbətinizdən silinəcək. Profildən çıxsın?',
    userPlaceholder: 'İstifadəçi adı',
    emailPlaceholder: 'email',
    copy: 'Link kopyalandı',
    history: {
        repeat: 'Sifarişi təkrarlayın',
        more: 'Daha ətraflı',
        order: 'Sifariş №',
        cancel: 'Sifarişi ləğv edin',
        amount: 'Qiymət: ',
        deliveryAmount: 'Çatdırılma: ',
        quantity: 'Sayı: ',
    },
};

export default profile;
