const cartDrawer = {
    cart: {
        title: 'sepet',
        relatedTitle: 'bu ürün ile genellikle almak',
        empty: 'Sepette boş :(',
        backButton: 'geri',
    },
    auth: {
        title: 'Yetkilendirme',
        description:
            'Rezervasyon yapmak, ikramiye almak ve daha fazlasını yapmak için giriş yapın veya kaydolun',
        btnAuth: 'Giriş / Kayıt',
        btnAnon: 'Kayıt olmadan sipariş verin',
    },
    login: {
        title: 'Giriş / Kayıt',
    },
    anon: {
        title: 'Lütfen numarayı belirtin',
        description:
            'Operatörün siparişi onaylayabilmesi ve gerekirse kuryenin sizinle iletişime geçebilmesi için',
        btnSend: 'Gönder',
        orAuth: {
            or: 'veya',
            auth: 'Giriş yap',
            description:
                'satın alma geçmişini görmek, puan toplamak ve siparişleri birkaç tıklamayla tekrarlamak için',
        },
    },
    back: 'geri',
};

export default cartDrawer;
