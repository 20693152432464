export default class URLHelper {
    static buildUrl(url: string, params: { [x: string]: string | number }) {
        Object.keys(params).forEach(key => {
            url = url.replace(new RegExp(`{${key}}`, 'g'), `${params[key]}`);
        });
        return url;
    }

    static isUrl(string: string) {
        const template = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        return template.test(string);
    }

    static getParams(url: string) {
        const paramString = url.split('?')[1];
        if (!paramString) {
            return {};
        }

        return paramString.split('&').reduce((acc, curr) => {
            const [key, value] = curr.split('=');
            acc[key] = value;
            return acc;
        }, {} as Record<string, string>);
    }
}
