const errors = {
    error: 'Error: {{message}}',
    accessError: 'Access error. Please sign in again',
    addReturnedBottle: 'Failed to add returned bottle',
    reduceReturnedBottle: 'Failed to reduce returned bottle',
    addressExists: 'Error. This address already exists',
    assignInterval: 'Failed to assign selected interval',
    assignAddress: 'Failed to assign selected address',
    assignPromocode: 'Failed to assign promocode',
    fetchProductSets: 'Failed to fetch product sets',
    reduceProductSet: 'Failed to reduce product set quantity in cart',
    addProductSet: 'Failed to add product set to cart',
    changeProductQuantity: 'Failed to change product quantity in cart',
    changeProductSetQuantity: 'Failed to change product set quantity in cart',
    createAddress: 'Failed to create address',
    createAnonAddress: 'Failed to create anonymous address',
};

export default errors;
