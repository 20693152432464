import NetworkStatus from '../../utils/enums/NetworkStatus';
import { THistory, Types, THistoriesActions, TPagination } from '../actions/history';

export declare type HistoryState = {
    items: null | Record<number, THistory[]>;
    pagination: TPagination | null;
    networkStatus: NetworkStatus;
};

const initState: HistoryState = {
    items: null,
    pagination: null,
    networkStatus: NetworkStatus.pending,
};

const histories = (state = initState, action: THistoriesActions): HistoryState => {
    let newState;
    switch (action.type) {
        case Types.SET_HISTORIES:
            newState = { ...state, items: action.payload };
            break;
        case Types.SET_PAGINATION:
            newState = { ...state, pagination: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default histories;
