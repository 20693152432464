import { createStore, compose, applyMiddleware } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';

import { getAppConfig } from './../utils/appConfig';
import rootReducer, { AppStateType } from './reducers';
import { API, Services } from '../entities/AppConfigEntity';
import { httpClientServices } from '../utils/httpClient';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const { api, services } = getAppConfig();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type ThunkExtra = {
    api: API;
    httpClientServices: typeof httpClientServices;
    services: Services;
};

export declare type TAction<Dispatch> = ThunkAction<Dispatch, AppStateType, ThunkExtra, any>;

export const middleware = [
    thunk.withExtraArgument<ThunkExtra>({
        api,
        httpClientServices,
        services,
    }),
];

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
