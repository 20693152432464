const catalog = {
    addressSelector: {
        title: 'My addresses',
        addAddress: '+ Add address',
    },
    enterAddressPanel: {
        title: 'Enter your address to display actual products and prices',
        buttonMap: 'Show map',
        buttonNotFoundAddress: "Can't find address",
        addAddress: '+ Add address',
        inputPlaceholder: 'Enter the city, street and house',
    },
    confirmDeleteAddress: 'Are you sure you want to delete the address?',
    confirmClearCart:
        'Are you sure you want to delete selected address? Your current cart will be emptied.',
};

export default catalog;
