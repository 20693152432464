import React from 'react';

import { YMInitializer } from 'react-yandex-metrika';

import { getAppConfig } from '../../utils/appConfig';

const YandexMetrika: React.FC = ({ children }) => {
    const { yandexMetrikaId } = getAppConfig();

    return (
        <>
            <YMInitializer accounts={[+yandexMetrikaId]} options={{ webvisor: true }} version="2" />
            {children}
        </>
    );
};

export default YandexMetrika;
