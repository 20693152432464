const cart = {
    product: {
        title: 'Cart',
        relatedTitle: 'With this product usually take',
        cartEmpty: 'Cart is empty :(',
        backToCatalog: 'Back to shopping',
        next: 'Next',
        autoProduct: 'Added automatically',
        returnedBottles: {
            title: 'Returned bottles',
            buttons: {
                addBottles: 'Add bottles',
                skipBottles: 'Continue without bottles',
            },
        },
        deletedProduct: 'Product is deleted',
        restoreProductBtn: 'Restore',
    },
    order: {
        title: 'Delivery',
        payment: 'Online Payment',
        addAddress: {
            title: 'Delivery address',
            subtitle: 'So that the courier can find you',
            btnOpen: 'Add address',
            loading: 'Loading addresses',
            back: 'Back',
            noFoundAdress: 'Did not find the right address',
            manuallyLocation:
                'Specify the exact place on the map where you want to deliver the order',
            mainLocation: 'Locality: ',
            secondaryLocation: 'Street / District: ',
            coordinates: 'Coordinates: ',
            btnConfirm: 'Confirm',
            btnNext: 'Next',
            fullAddress: 'Enter full address',
            street: 'Street',
            house: 'House',
            entrance: 'Entrance',
            floor: 'Floor',
            room: 'Room',
            name: 'Name, such as Home or Office',
            noRoomNumber: 'No room number',
            comment: 'Comment',
            errorLocationSearch: 'Sorry, your address was not found',
            placeholder: 'Enter the city, street and house',
            notFound: 'Nothing found',
        },
        addressField: {
            title: 'Delivery address',
            noAddresses: 'No addresses',
            addAddress: 'Select address',
            changeAddress: 'Change address',
            city: '',
            street: '',
            house: 'hse.',
            entrance: 'ent.',
            floor: 'fl.',
            room: 'rm.',
        },
        deliveryTime: {
            title: 'Delivery time',
            loading: 'Loading Intervals',
            noTimeForAdress: 'There is no time available for the specified address',
        },
        bonusField: {
            title: 'Bonus points',
            useBonuses: 'Use points',
            notSufficientBonuses: 'Not enough points to pay for an order',
            firstOrder: 'Place an order to start accumulating points.',
            payExtra: 'It will be necessary to pay extra {{value}}',
            more: 'Read more',
        },
        phoneNumber: 'Phone number',
        enterPhoneNumber: 'Enter phone number',
        notValidPhoneNumber: 'Enter a valid phone number',
        orderCost: 'Order cost',
        orderSum: 'Order total: ',
        deliveryCost: 'Delivery: ',
        paymentMethod: 'Payment method',
        paymentLoading: 'Loading payment methods',
        errorPaymentLoading: 'Error getting types of payment',
        promocodeTitle: 'Enter the promocode',
        apply: 'Apply',
        promocode: 'Promocode',
        comment: 'Comment to order',
        pc: ' pc.',
        addressNotSelected: 'Delivery address not specified',
    },
    footer: {
        returnedBottles: 'Bottles for return',
        helpMessageOk: 'Ok, I understand',
        infoMessage: 'The number of bottles for return cannot be more than bottles with water',
        sum: 'Total: ',
        bonuses: ' bonuses',
        shortBonuses: ' b',
        delivery: 'Delivery: ',
        сheckout: 'Checkout',
        total: 'To pay: ',
    },
};

export default cart;
