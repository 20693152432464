import { AxiosRequestConfig } from 'axios';

const getHeaderURLValue = (config: AxiosRequestConfig) => {
    if (config.url?.match(/^http/)) {
        return config?.url;
    }

    let baseURL = config?.baseURL;
    if (!baseURL?.endsWith('/')) {
        baseURL = `${baseURL}/`;
    }

    let path = config?.url;
    if (path?.startsWith('/')) {
        path = path.slice(1);
    }

    return `${baseURL}${path}`;
};

export { getHeaderURLValue };
