const padTo2Digits = (num: number) => {
    return `${num}`.padStart(2, '0');
};

export class DateHelper {
    static formatDate(date: Date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('.');
    }
}
