import Axios from 'axios';
import { batch } from 'react-redux';

import { CartActions, TCartItem } from './cart';
import { TProductSet } from './catalog';
import { OrderActions, TPayHistoryOrder } from './order';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { OrderPaymentSources } from '../../utils/enums/OrderPaymentSources';
import URLHelper from '../../utils/URLHelper';
import { TAction } from '../store';

export const Types = {
    FETCH_HISTORY: 'HISTORY@FETCH:HISTORY',
    SET_HISTORIES: 'HISTORY@SET:HISTORIES',
    SET_PAGINATION: 'HISTORY@SET:PAGINATION',
    SET_NETWORK_STATUS: 'HISTORY@SET:NETWORK:STATUS',
};

export type THistoryProduct = {
    id: number;
    productId: number;
    productName: string;
    quantity: number;
    productPrice: {
        amount: string;
        currency: string;
    };
    image: string;
};

export type THistoryProductSet = {
    productSetId: number;
    productSetName: string;
    productSetPrice: {
        amount: string;
        currency: string;
    };
    products: TProductSet[];
    quantity: number;
    image: string;
};

export type THistory = {
    id: number;
    uuid: string;
    cart: string;
    contractNumber: number;
    statusEnum: string;
    paymentType: string;
    products: THistoryProduct[];
    productSets: THistoryProductSet[];
    total: {
        amount: string;
        currency: string;
    };
    deliveryCost: {
        amount: string;
        currency: string;
    };
    paidAmount: {
        amount: string;
        currency: string;
    };
    coinsAmount: {
        amount: string;
        currency: string;
    };
    createdAt: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    shippingDate: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    shippingDateTo: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    completedAt: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    shipping: {
        address: string;
        date: string;
        interval: string;
    };
    paymentStatus: string;
    paymentUrl: null;
    paymentMethodId: string | null;
    coinCashBack: number;
    coinAccrued: false;
    clientComment: null;
    needToPay: boolean;
    context: {
        routeUrl: string;
        items: [
            {
                quantity: number;
                price: number;
                text: string;
            },
        ];
        discount: string;
    };
};

export type TSetHistoryItems = {
    type: typeof Types.SET_HISTORIES;
    payload: Record<number, THistory[]> | null;
};

export type TSetHistoryPagination = {
    type: typeof Types.SET_PAGINATION;
    payload: TPagination | null;
};

export type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TPagination = {
    total_count: number;
    count_per_page: number;
    current_page_number: number;
    has_next_page: boolean;
};

type TFetchHistoryResponse = {
    success: boolean;
    pagination: TPagination;
    data: THistory[];
};
export type THistoriesActions = TSetHistoryItems | TSetHistoryPagination;

type TReorderResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    createdAt: string;
    id: string;
    order: string;
};

type TCartProducts = Record<number, TCartItem>;

type HistoriesActionsType = {
    fetchHistories: (page?: number, count?: number) => TAction<Promise<void>>;
    clearHistories: () => TAction<Promise<void>>;
    addOrder: (history: THistory) => TAction<Promise<void>>;
    payForOrder: (history: THistory) => TAction<Promise<void>>;
    setHistories: (histories: Record<number, THistory[]> | null) => TSetHistoryItems;
    setPagination: (pagination: TPagination) => TSetHistoryPagination;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
};

export const HistoriesAction: HistoriesActionsType = {
    fetchHistories(page, count) {
        return async (dispatch, getState, { httpClientServices, api }) => {
            if (!page) {
                page = 1;
            }
            const items = getState().history.items;
            if (items && items[page]) {
                return;
            }
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            const url = URLHelper.buildUrl(api.getHistories, {
                page,
                count: count || 10,
            });
            httpClientServices
                .getClient()
                .get<TFetchHistoryResponse>(url)
                .then(({ data }) => {
                    if (data.success) {
                        const histories = getState().history.items || {};
                        histories[page || 1] = data.data;
                        batch(() => {
                            dispatch(this.setHistories(histories));
                            if (data.pagination.current_page_number === 1) {
                                dispatch(this.setPagination(data.pagination));
                            }
                            dispatch(this.setNetworkStatus(NetworkStatus.ready));
                        });
                    }
                });
        };
    },
    clearHistories() {
        return async (dispatch, getState) => {
            const items = getState().history.items;
            if (items) {
                batch(() => {
                    dispatch(this.setHistories(null));
                });
            }
        };
    },
    addOrder(history) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { catalog, productSets } = getState().catalog;
            const products = catalog.map(item => item.products).flat();

            const data = {
                externalOrder: `/order/external_orders/${history.uuid}`,
            };
            const cartProducts: TCartProducts = {};
            history.products.forEach(hProduct => {
                const product = products.find(product => product.id === hProduct.productId);
                if (product) {
                    cartProducts[product.id] = {
                        productId: product.id,
                        quantity: hProduct.quantity,
                    };
                }
            });
            history.productSets.forEach(hProductSet => {
                const productSet = productSets.find(pSet => pSet.id === hProductSet.productSetId);
                if (productSet) {
                    cartProducts[productSet.id] = {
                        productId: productSet.id,
                        quantity: hProductSet.quantity,
                    };
                }
            });
            await httpClientServices
                .getClient('cart')
                .post<TReorderResponse>(services.cart.reorder, data)
                .then(({ data }) => {
                    batch(() => {
                        dispatch(CartActions.setInfoCart(data));
                        dispatch(CartActions.setCartItems(cartProducts));
                        dispatch(CartActions.calculate());
                        localStorage.setItem('cartId', data.cart);
                    });
                })
                .catch(error => {
                    if (Axios.isCancel(error)) {
                        return;
                    }
                    throw error;
                });
        };
    },
    payForOrder(history) {
        return async (dispatch, getState) => {
            const user = getState().user.data;
            const payments = getState().order.payments;
            if (user) {
                const payment = payments.find(
                    payment => payment.description === history.paymentType,
                );
                if (payment && user.uuid) {
                    const order: TPayHistoryOrder = {
                        orderDetails: {
                            id: history.id,
                            uuid: history.uuid,
                            payment: {
                                orderNumber: history.id,
                                clientId: user.uuid || '',
                                amount: +history.total.amount - +history.paidAmount.amount,
                            },
                            address: {
                                name: history.shipping.address,
                            },
                            client: {
                                uuid: user.uuid,
                            },
                            total: Number(history.total.amount),
                            context: history.context,
                            paymentMethodId: history.paymentMethodId || payment['@id'],
                            currency: history.paidAmount.currency,
                        },
                    };
                    dispatch(
                        OrderActions.sendOnlinePayment(order, OrderPaymentSources.CreateOrder),
                    );
                }
            }
        };
    },
    setHistories(histories) {
        return {
            type: Types.SET_HISTORIES,
            payload: histories,
        };
    },
    setPagination(pagination) {
        return {
            type: Types.SET_PAGINATION,
            payload: pagination,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
