const banners = {
    type: {
        clickBanner: 'Reklam banneri',
        infoBanner: 'Məlumat banneri',
        storyBanner: 'Tarix',
        productSetBanner: 'Məhsul dəsti',
    },
    preview: 'Önizləmə',
    makeOrder: 'Sifariş verin',
};

export default banners;
