import React from 'react';

import { Empty } from 'antd';

import View from './View';
import { t } from '../i18n';

const EmptyPanel: React.FC<{ containerStyle?: React.CSSProperties }> = ({ containerStyle }) => {
    return (
        <View className="not-found" style={containerStyle}>
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('ui.noData')}
            />
        </View>
    )
}

export default EmptyPanel;