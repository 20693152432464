const layout = {
    header: {
        company: 'Компания',
        user: 'Пользователь',
        subtitle: 'Доставка в городе',
        buttonBack: 'Назад',
        buttonCheckout: 'Оформить заказ',
    },
    mobileMenu: {
        company: 'Настройки компании',
        user: 'Мой профиль',
    },
    footer: {
        contractOffer: 'Договор оферты',
        privacyPolicy: 'Политика конфиденциальности',
        userAgreement: 'Пользовательское соглашение',
        partnerWebsite: 'Сайт партнера App Solution',
    },
    buttons: {
        logout: 'Выйти',
    },
};

export default layout;
