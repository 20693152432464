const profile = {
    title: 'Kabine',
    myAddress: 'Adreslerim',
    historyOrders: 'sipariş geçmişi',
    messages: 'destekle sohbet et',
    bonuses: 'Bonuslarınız',
    share: 'bağlantıyı paylaş',
    delivery: 'Teslimat ve ödeme',
    aboutCompany: 'şirket hakkında',
    downloadApp: 'Uygulamayı indirin',
    signOut: 'Profilden çık',
    btnLogoutOk: 'Evet, dışarı çık',
    deleteAccount: 'Hesabı sil',
    modalDeleteAccount: {
        title: 'Hesabı sil?',
        btnDelete: 'Evet',
        btnClose: 'Hayir',
    },
    messageSignOut: 'Profilden çıktığınızda tüm ürünler sepetten silinir. Profilden çıkmak mı?',
    userPlaceholder: 'Kullanıcı adı',
    emailPlaceholder: 'email',
    copy: 'Bağlantı kopyalandı',
    history: {
        repeat: 'Siparişi tekrarla',
        more: 'devamı',
        order: 'Sipariş No.',
        cancel: 'siparişi iptal et',
        amount: 'Maliyet: ',
        deliveryAmount: 'teslimat: ',
        quantity: 'Sayı: ',
    },
};

export default profile;
