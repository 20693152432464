const banners = {
    type: {
        clickBanner: 'Reklam afişi',
        infoBanner: 'Bilgi başlığı',
        storyBanner: 'Hikaye',
        productSetBanner: 'Yemek seti',
    },
    preview: 'Ön izleme',
    makeOrder: 'Donatmak',
};

export default banners;
