import { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { batch } from 'react-redux';

import { CatalogsActions, TCatalogProduct } from './catalog';
import { PaymentActions } from './payment';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAction } from '../store';

export const Types = {
    SET_CELL: 'AQUAMARKET@SET:CELL',
    SET_RETURNABLE_CONTAINER: 'AQUAMARKET@SET:RETURNABLE:CONTAINER',
    SET_IS_SBP_PAYMENT: 'AQUAMARKET@SET:IS:SBP:PAYMENT',
    SET_CELL_NETWORK_STATUS: 'AQUAMARKET@SET:NETWORK:STATUS',
    SET_CALCULATE: 'AQUAMARKET@SET:CALCULATE',
    SET_CALCULATE_NETWORK_STATUS: 'AQUAMARKET@SET:CALCULATE:NETWORK:STATUS',
};

type TResponseBase = {
    '@id': string;
    '@type': string;
};

export type TProduct = {
    id: string;
    monolithId: number;
    name: string;
    type: string;
};

type TAdditionalProduct = {
    '@type': string;
    quantity: number;
    product: TResponseBase & {
        createdAt: string;
        id: string;
        monolithId: number;
        name: string;
        partner: string;
        type: string;
        updatedAt: string;
    };
};

type TProductInSet = TResponseBase & {
    product: TProduct;
    productPrice: number;
    productQuantity: number;
    productTotal: number;
};

type TActiveSet = TResponseBase & {
    insufficientBottlesToReturn?: number;
    productsInSet: TProductInSet[];
};

type TDefaultProduct = TResponseBase & {
    name: string;
    monolithId: number;
    type: string;
    defaultPrice: number;
};

type TBox = TResponseBase & {
    active: boolean;
};

type TCreateOrder = {
    sourceSite: string;
    cell: string;
    usePoints: boolean;
    additionalProducts?: {
        product: string;
        quantity: number;
    }[];
};

type TSum = {
    amount: string;
    currency: string;
};
export type TCalculate = TResponseBase & {
    cart: TResponseBase & {
        products: Array<
            TResponseBase & {
                cashback: TSum;
                cashbackSum: TSum;
                name: string | null;
                price: TSum;
                productId: number;
                quantity: number;
                sum: TSum;
            }
        >;
    };
    cartSum: TSum;
    cashbackSum: TSum;
    deliveryCost: TSum;
    maxCashbackPoints: TSum;
    returnedBottles: number;
    sum: TSum;
    total: TSum;
};

export type TOrderResponse = TResponseBase & {
    '@context': string;
    additionalProducts: TAdditionalProduct[];
    cell: string;
    result: {
        '@type': string;
        orderDto: any;
        paymentMethodId: string;
    };
    externalOrder: any;
};

export type TCell = {
    '@context': string;
    '@id': string;
    '@type': string;
    activeSet: TActiveSet;
    defaultProduct: TDefaultProduct;
    enabled: boolean;
    box: TBox;
};

export type TReturnableContainer = TCatalogProduct & {
    uuid: string;
};

type TSetCell = {
    type: typeof Types.SET_CELL;
    payload: TCell;
};

type TSetReturnableContainer = {
    type: typeof Types.SET_RETURNABLE_CONTAINER;
    payload: TReturnableContainer | null;
};

type TSetIsSbpPayment = {
    type: typeof Types.SET_IS_SBP_PAYMENT;
    payload: boolean;
};

type TSetCellNetworkStatus = {
    type: typeof Types.SET_CELL_NETWORK_STATUS;
    payload: NetworkStatus;
};

type TSetCalculate = {
    type: typeof Types.SET_CALCULATE;
    payload: TCalculate;
};

type TSetCalculateNetworkStatus = {
    type: typeof Types.SET_CALCULATE_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TAquamarketActions = TSetCell;

type AquamarketActionsType = {
    fetchCell: (uuid: string) => TAction<Promise<void>>;
    fetchReturnableContainer: () => TAction<Promise<void>>;
    createOrder: (params: {
        cell: string;
        returnedBottles: number | undefined;
        usePoints?: boolean;
    }) => TAction<Promise<void | AxiosResponse<any>>>;
    payForOrder: (paymentUrl: string, paymentMethodId: string) => TAction<Promise<void>>;
    calculate: (params: {
        cell: string;
        returnedBottles: number;
        usePoints?: boolean;
    }) => TAction<Promise<void>>;
    setCell: (cell: TCell) => TSetCell;
    setCellNetworkStatus: (status: NetworkStatus) => TSetCellNetworkStatus;
    setReturnableContainer: (product: TReturnableContainer | null) => TSetReturnableContainer;
    setIsSbpPayment: (isSbp: boolean) => TSetIsSbpPayment;
    setCalculate: (calculate: TCalculate) => TSetCalculate;
    setCalculateNetworkStatus: (status: NetworkStatus) => TSetCalculateNetworkStatus;
};

const defaultAquamarketHeaders = {
    'X-Source': 'aquamarket',
};

const aquamarketBaseUrl = 'https://aquamarket.service.appsol.ru/';

export const AquamarketActions: AquamarketActionsType = {
    fetchCell(uuid) {
        return async (dispatch, _, { httpClientServices }) => {
            dispatch(this.setCellNetworkStatus(NetworkStatus.loading));
            const instance = httpClientServices.getClient();
            const path = `aquamarket/cells/${uuid}`;
            const url = `${aquamarketBaseUrl}${path}`;

            axiosRetry(instance, {
                retries: 3,
                retryCondition: () => true,
                retryDelay: () => 2000,
            });

            instance
                .get<TCell>(url)
                .then(({ data }) => {
                    batch(() => {
                        dispatch(this.setCell(data));
                    });
                })
                .catch(() => {})
                .finally(() => {
                    dispatch(this.setCellNetworkStatus(NetworkStatus.ready));
                });
        };
    },
    fetchReturnableContainer() {
        return async (dispatch, _, { httpClientServices }) => {
            const path = 'aquamarket/products.json?type=RETURNABLE_CONTAINER';
            const url = `${aquamarketBaseUrl}${path}`;
            httpClientServices
                .getClient()
                .get<TProduct[]>(url)
                .then(({ data }) => {
                    const returnableContainer = data[0];
                    if (returnableContainer) {
                        const productInCatalog = dispatch(CatalogsActions.getProduct(2));
                        if (productInCatalog) {
                            const product: TReturnableContainer = {
                                ...productInCatalog,
                                uuid: returnableContainer.id,
                            };
                            dispatch(this.setReturnableContainer(product));
                        }
                    }
                });
        };
    },
    createOrder({ cell, returnedBottles, usePoints = false }) {
        return async (_, getState, { httpClientServices }) => {
            const data: TCreateOrder = {
                cell,
                usePoints,
                sourceSite: window.location.origin,
            };
            const { returnableContainer } = getState().aquamarket;
            if (returnedBottles) {
                data.additionalProducts = [
                    {
                        product: `/aquamarket/products/${returnableContainer?.uuid}`,
                        quantity: returnedBottles,
                    },
                ];
            }
            const path = 'aquamarket/orders';
            const url = `${aquamarketBaseUrl}${path}`;
            return httpClientServices
                .getClient()
                .post<TOrderResponse>(url, data, {
                    headers: {
                        ...defaultAquamarketHeaders,
                        'device-info': JSON.stringify({
                            uniqueDeviceId: localStorage.getItem('device-id'),
                        }),
                    },
                })
                .then(res => res);
        };
    },
    payForOrder(paymentUrl, paymentMethodId) {
        return async (dispatch, getState) => {
            const { payments } = getState().order;
            const sbpPaymentMethodId = payments.find(payment => payment.setup.sbp === true)?.[
                '@id'
            ];
            if (sbpPaymentMethodId && paymentMethodId === sbpPaymentMethodId) {
                dispatch(this.setIsSbpPayment(true));
            }

            dispatch(PaymentActions.setPaymentUrl(paymentUrl));
        };
    },
    calculate({ cell, returnedBottles, usePoints = false }) {
        return async (dispatch, getState, { httpClientServices }) => {
            const returnableContainer = getState().aquamarket.returnableContainer;
            const additionalProducts =
                returnedBottles === 0
                    ? undefined
                    : [
                          {
                              product: `/aquamarket/products/${returnableContainer?.uuid}`,
                              quantity: returnedBottles,
                          },
                      ];
            const path = 'aquamarket/calculate_cart_requests';
            const url = `${aquamarketBaseUrl}${path}`;
            dispatch(this.setCalculateNetworkStatus(NetworkStatus.loading));
            httpClientServices
                .getClient()
                .post<TCalculate>(
                    url,
                    {
                        cell,
                        usePoints,
                        additionalProducts,
                    },
                    {
                        headers: defaultAquamarketHeaders,
                    },
                )
                .then(({ data }) => {
                    batch(() => {
                        dispatch(this.setCalculate(data));
                        dispatch(this.setCalculateNetworkStatus(NetworkStatus.ready));
                    });
                })
                .catch(e => {
                    throw e;
                });
        };
    },
    setCell(cell) {
        return {
            type: Types.SET_CELL,
            payload: cell,
        };
    },
    setReturnableContainer(product) {
        return {
            type: Types.SET_RETURNABLE_CONTAINER,
            payload: product,
        };
    },
    setIsSbpPayment(isSbp) {
        return {
            type: Types.SET_IS_SBP_PAYMENT,
            payload: isSbp,
        };
    },
    setCalculate(calculate) {
        return {
            type: Types.SET_CALCULATE,
            payload: calculate,
        };
    },
    setCellNetworkStatus(status) {
        return {
            type: Types.SET_CELL_NETWORK_STATUS,
            payload: status,
        };
    },
    setCalculateNetworkStatus(status) {
        return {
            type: Types.SET_CALCULATE_NETWORK_STATUS,
            payload: status,
        };
    },
};
