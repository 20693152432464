import { Dayjs } from 'dayjs';
import { FieldRenderProps } from 'react-final-form';

export type TFetchResult<T = any> = {
    data: {
        [P in keyof T]: T[P];
    } &
        FetchErrors;
    message?: string;
    success: boolean;
    errorType?: 'requestError' | 'validationError' | 'ValidationError';
    errors?: { [fieldName: string]: string[] };
};

type FetchErrors = {
    errors?: { [x: string]: string[] };
    timestamp?: number;
};

export type GeocodingAddress = {
    address: {
        house_number?: string;
        road?: string;
        city?: string;
        country?: string;
        hamlet?: string;
        town?: string;
        village?: string;
        municipality?: string;
        suburb?: string;
        neighborhood?: string;
        county?: string;
    };
    lat: number;
    lon: number;
    place_id?: number;
};

export declare type TCoordinates = {
    longitude: number;
    latitude: number;
};

export type TPredictionRequest = 'geocode' | 'city';

export type TTypePrediction =
    | 'country'
    | 'state'
    | 'county'
    | 'city'
    | 'district'
    | 'house'
    | 'street'
    | 'locality';

export type TPrediction = {
    place_id: string;
    description: string;
    country?: string | null;
    countrycode?: string | null;
    state?: string | null;
    county?: string | null;
    city?: string | null;
    district?: string | null;
    housenumber?: string | null;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
    };
    street?: string | null;
    coordinates: TCoordinates;
    types: TTypePrediction[];
};

export type TPredictionResult = {
    predictions: TPrediction[];
    status: 'OK' | 'ERROR';
};

export type TFieldRenderProps<Value> = FieldRenderProps<Value, any>;

export type Period = {
    from: Dayjs;
    to: Dayjs;
};

export enum NamedPeriod {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
}

export type TokenPayload = {
    iat: number;
    roles: UserRoles[];
    username: string;
    exp: number;
    temp: boolean;
    uuid: string;
    first_name: string | null;
    middle_name: string | null;
    family_name: string | null;
    partner_id?: string;
    legal_person_id?: string;
};

export enum UserRoles {
    RoleUser = 'ROLE_USER',
    RoleAdmin = 'ROLE_ADMIN',
    RoleClient = 'ROLE_CLIENT',
    AnonymousAggregatorClient = 'ANONYMOUS_AGGREGATOR_CLIENT',
    RoleAggregatorUser = 'ROLE_AGGREGATOR_USER',
    RoleOwner = 'ROLE_OWNER',
    RoleAnonymousPartnerClient = 'ROLE_ANONYMOUS_PARTNER_CLIENT',
    RoleClientApp = 'ROLE_CLIENT_APP',
}

export type ServicesIdentity = {
    token: string;
    refreshToken: string;
};
