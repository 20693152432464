import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import { t } from '../../aqua-delivery-web-client-ui/i18n';
import { TCategory, TProduct } from '../../routes/catalog/hooks/useCatalog';
import { translateMap } from '../../translations';
import { getAppConfig } from '../../utils/appConfig';
import { DateHelper } from '../../utils/DateHelper';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { getLocale } from '../../utils/httpClient';
import { TAction } from '../store';

const tMap = translateMap.errors;

export const Types = {
    SET_PRODUCTS: 'CATALOG@SET:PRODUCTS',
    SET_PRODUCT_SETS: 'CATALOG:SET:PRODUCT:SETS',
    SET_CATEGORIES: 'CATALOG@SET:CATEGORIES',
    SET_CATALOG: 'CATALOG@SET:CATALOG',
    SET_NETWORK_STATUS: 'CATALOG@SET:NETWORK:STATUS',
    SET_ACTIVE_TAB: 'CATALOG@SET:ACTIVE:TAB',
    SET_IS_TOUCH_TAB: 'CATALOG@SET:IS:TOUCH:TAB',
};

export type TCatalog = {
    id: number;
    sequence: number;
    name: string;
    description: string | null;
    is_visible: number;
    is_promotion: number;
    updated_at: string;
    created_at: string;
    parent_id: string | null;
    products: TCatalogProduct[];
};

export type TCatalogProduct = {
    id: number;
    sequence: number;
    name: string;
    description: string;
    price: number;
    premium_price: number;
    volume: string;
    volume_ml: number | null;
    count: number;
    is_visible: number;
    created_at: string;
    updated_at: string;
    category_id: number;
    image: string;
    image_thumbnail: string;
    images: {
        id: number;
        image: string;
        image_thumbnail: string;
    }[];
    related_products: {
        id: number;
    }[];
    type: string;
    cash_back_percent: string;
    cash_back_sum: number;
    min_quantity: number;
    max_quantity: number | null;
    stock: number | null;
    product_set_id: number | null;
};

export type TProductSet = {
    id: number;
    description: string;
    mainImageThumbUrl: string;
    mainImageUrl: string;
    maxQuantityInOrder: number | null;
    minQuantityInOrder: number | null;
    name: string;
    price: number;
    products: any[];
    uuid: string;
};

export type TCombinedProduct = (TCatalogProduct | TProductSet) & {
    mainImgUrl: string;
    mainImgThumbUrl: string;
    relatedProducts?: number[];
    type?: string;
    cashbackSum: number;
    minQuantity: number | null;
    maxQuantity: number | null;
};

export type TProductsAction = {
    type: typeof Types.SET_PRODUCTS;
    payload: Record<number, { totalCount: number; products: Record<number, TProduct[]> }>;
};

export type TSetLoadedAction = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

type TCategoriesAction = {
    type: typeof Types.SET_CATEGORIES;
    payload: TCategory[];
};

type TSetActiveTabAction = {
    type: typeof Types.SET_CATEGORIES;
    payload: number;
};

type TSetIsTouchTab = {
    type: typeof Types.SET_IS_TOUCH_TAB;
    payload: boolean;
};

type TCatalogAction = {
    type: typeof Types.SET_CATALOG;
    payload: TCatalog[];
};

type TSetProductSetsAction = {
    type: typeof Types.SET_PRODUCT_SETS;
    payload: TProductSet[];
};

export type CatalogActions = TProductsAction | TCategoriesAction | TCatalogAction;

type CatalogsActionsType = {
    fetchAllProducts: () => TAction<Promise<void>>;
    fetchProductSets: () => TAction<Promise<void>>;
    setCategories: (categories: TCategory[]) => TCategoriesAction;
    setCatalog: (catalog: TCatalog[]) => TCatalogAction;
    getProduct: (catalog: number) => TAction<TCatalogProduct | undefined>;
    getProductSet: (id: number) => TAction<TProductSet | undefined>;
    setProductSets: (sets: TProductSet[]) => TSetProductSetsAction;
    setProducts: (
        products: Record<number, { totalCount: number; products: Record<number, TProduct[]> }>,
    ) => TProductsAction;
    setNetworkStatus: (status: NetworkStatus) => TSetLoadedAction;
    setActiveTab: (value: number) => TSetActiveTabAction;
    setIsTouchTab: (value: boolean) => TSetIsTouchTab;
};

type Pagination = {
    total_count: number;
    count_per_page: number;
    current_page_number: number;
    has_next_page: boolean;
};

const catalogCancelToken = Axios.CancelToken.source();

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    if (error.response && error.response.status !== 404) {
        const errorMessage = error?.message
            ? `${defaultErrorMessage}. ${error?.message}`
            : defaultErrorMessage;
        message.error(errorMessage);
    }
};

export const CatalogsActions: CatalogsActionsType = {
    getProduct(id) {
        return (_, getState) => {
            const { catalog } = getState();

            let product: TCatalogProduct | undefined = undefined;

            catalog.catalog.forEach(category => {
                category.products.forEach((item: TCatalogProduct) => {
                    if (item.id === id) {
                        product = item;
                    }
                });
            });

            return product;
        };
    },
    getProductSet(id) {
        return (_, getState) => {
            const { catalog } = getState();

            return catalog.productSets.find(item => item.id === id);
        };
    },
    fetchProductSets() {
        return async (dispatch, getState) => {
            const path = '/v1/catalog/product-sets';
            const url = `${getAppConfig().url}${path}`;
            const { addresses } = getState();
            const payment = getState().order.selectedPayment;
            const cityId = addresses.selectedAddress?.city_id;
            dispatch(this.setNetworkStatus(NetworkStatus.loading));

            return Axios.get<TProductSet[]>(url, {
                params: {
                    date: DateHelper.formatDate(new Date()),
                    paymentType: payment?.['@type'] || 1,
                    cityId: cityId,
                },
            })
                .then(({ data }) => {
                    batch(() => {
                        dispatch(this.setNetworkStatus(NetworkStatus.ready));
                        dispatch(this.setProductSets(data));
                    });
                })
                .catch(e => {
                    dispatch(this.setNetworkStatus(NetworkStatus.ready));
                    errorHandler(e, t(tMap.fetchProductSets));
                });
        };
    },
    fetchAllProducts() {
        return async (dispatch, getState, { api, httpClientServices }) => {
            const coordinates = localStorage.getItem('geolocationCoordinates')?.split(',');
            const { addresses } = getState();
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            const cityId = addresses.selectedAddress?.city_id;
            const location = cityId
                ? { cityId }
                : coordinates && { latitude: coordinates[0], longitude: coordinates[1] };
            return httpClientServices
                .getClient()
                .get(api.products, {
                    params: {
                        date: DateHelper.formatDate(new Date()),
                        cityId,
                        lang: getLocale(),
                        source: 'site',
                        ...location,
                    },
                    cancelToken: catalogCancelToken.token,
                })
                .then(({ data }) => {
                    if (data.success) {
                        batch(() => {
                            dispatch(this.setNetworkStatus(NetworkStatus.ready));
                            dispatch(this.setCatalog(data.data));
                            dispatch(this.fetchProductSets());
                        });
                    }
                })
                .catch(e => {
                    dispatch(this.setNetworkStatus(NetworkStatus.ready));
                    errorHandler(e, 'Failed to fetch all products');
                });
        };
    },
    setProducts(products) {
        return {
            type: Types.SET_PRODUCTS,
            payload: products,
        };
    },
    setCategories(categories) {
        return {
            type: Types.SET_CATEGORIES,
            payload: categories,
        };
    },
    setCatalog(catalog) {
        return {
            type: Types.SET_CATALOG,
            payload: catalog,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
    setActiveTab(value) {
        return {
            type: Types.SET_ACTIVE_TAB,
            payload: value,
        };
    },
    setIsTouchTab(value) {
        return {
            type: Types.SET_IS_TOUCH_TAB,
            payload: value,
        };
    },
    setProductSets(sets) {
        return {
            type: Types.SET_PRODUCT_SETS,
            payload: sets,
        };
    },
};
