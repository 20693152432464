import { GeocodingAddress, TPrediction } from '../../types';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TYaPrediction, Types } from '../actions/addressPredictions';
import { TAddressPredictionsActions } from '../actions/addressPredictions';

export declare type PredictionsState = {
    predictions: TPrediction[];
    yandexPredictions: TYaPrediction[];
    yandexPredictionGeocode: GeocodingAddress | null;
    isYaAutocompleteEnabled: boolean;
    networkStatus: NetworkStatus;
};

const initState: PredictionsState = {
    predictions: [],
    yandexPredictions: [],
    yandexPredictionGeocode: null,
    isYaAutocompleteEnabled: true,
    networkStatus: NetworkStatus.pending,
};

const addressPredictions = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state = initState,
    action: TAddressPredictionsActions,
): PredictionsState => {
    let newState;
    switch (action.type) {
        case Types.SET_PREDICTIONS:
            newState = { ...state, predictions: action.payload };
            break;
        case Types.SET_YA_PREDICTIONS:
            newState = { ...state, yandexPredictions: action.payload };
            break;
        case Types.SET_YA_PREDICTION_GEOCODE:
            newState = { ...state, yandexPredictionGeocode: action.payload };
            break;
        case Types.SET_IS_YA_AUTOCOMPLETE_ENABLED:
            newState = { ...state, isYaAutocompleteEnabled: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default addressPredictions;
