import { TCategory, TProduct } from '../../routes/catalog/hooks/useCatalog';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { CatalogActions, Types, TCatalog, TProductSet } from '../actions/catalog';

export declare type CatalogState = {
    products: Record<number, { totalCount: number; products: Record<number, TProduct[]> }>;
    productSets: TProductSet[];
    categories: TCategory[];
    catalog: TCatalog[];
    activeTab: number;
    isTouchTab: boolean;
    networkStatus: NetworkStatus;
};

const initState: CatalogState = {
    products: {},
    productSets: [],
    categories: [],
    catalog: [],
    activeTab: 0,
    isTouchTab: false,
    networkStatus: NetworkStatus.pending,
};

const catalog = (state = initState, action: CatalogActions): CatalogState => {
    let newState;
    switch (action.type) {
        case Types.SET_PRODUCTS:
            newState = { ...state, products: action.payload };
            break;
        case Types.SET_PRODUCT_SETS:
            newState = { ...state, productSets: action.payload };
            break;
        case Types.SET_CATEGORIES:
            newState = { ...state, categories: action.payload };
            break;
        case Types.SET_ACTIVE_TAB:
            newState = { ...state, activeTab: action.payload };
            break;
        case Types.SET_CATALOG:
            newState = { ...state, catalog: action.payload };
            break;
        case Types.SET_IS_TOUCH_TAB:
            newState = { ...state, isTouchTab: action.payload };
            break;

        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default catalog;
