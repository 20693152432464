const errors = {
    accessError: 'Giriş xətası. Zəhmət olmasa yenidən daxil olun',
    addReturnedBottle: 'Qaytarma qabı əlavə edilərkən xəta baş verdi',
    reduceReturnedBottle: 'Qaytarma qabını silərkən xəta',
    addressExists: 'Əlavə edərkən xəta baş verdi. Bu ünvan artıq mövcuddur',
    assignInterval: 'Seçilmiş intervalı təyin etmək alınmadı',
    assignAddress: 'Seçilmiş ünvanı təyin etmək alınmadı',
    assignPromocode: 'Promo kodu tətbiq etmək alınmadı',
    fetchProductSets: 'Məhsul dəstlərinin alınmasında səhv',
    reduceProductSet: 'Səbətdəki məhsul dəstlərinin sayını azaldarkən xəta',
    addProductSet: 'Səbətə məhsul dəsti əlavə edərkən xəta baş verdi',
    changeProductQuantity: 'Səbətdəki malların miqdarını dəyişdirərkən xəta',
    changeProductSetQuantity: 'Səbətdəki məhsul dəstlərinin sayını dəyişdirərkən xəta',
    createAddress: 'Ünvan yaratma xətası',
    createAnonAddress: 'Anonim ünvan yaratarkən xəta baş verdi',
};

export default errors;
