const product = {
    title: '',
    count: 'Quantity',
    totalPay: ' Total priсe: ',
    addButton: ' Add',
    addedButton: 'Added',
    price: 'Price: ',
    promotion: 'The price can decrease if the number of items is more than 1',
    maxCountMessage: 'The maximum quantity of the current product has been reached',
    ct: 'pc.',
};

export default product;
