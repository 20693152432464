export default {
    months: {
        one: 'месяц',
        few: 'месяца',
        many: 'месяцев',
        other: 'месяцев',
    },
    days: {
        one: 'день',
        few: 'дня',
        many: 'дней',
        other: 'дней',
    },
    hours: {
        one: 'час',
        few: 'часа',
        many: 'часов',
        other: 'часов',
    },
    minutes: {
        one: 'минута',
        few: 'минуты',
        many: 'минут',
        other: 'минут',
    },
    partnersDative: {
        one: 'партнеру',
        few: 'партнерам',
        many: 'партнерам',
        other: 'партнерам',
    },
};
