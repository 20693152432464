import { BonusPointsAction, TBonusPointsHistoryItem, Types } from '../actions/bonusPoints';

export declare type TBonusPointsState = {
    history: TBonusPointsHistoryItem[] | null;
    aboutLoyaltySystem: string | null;
    isUsingBonusPoints: boolean;
};

const initState: TBonusPointsState = {
    history: null,
    aboutLoyaltySystem: null,
    isUsingBonusPoints: false,
};

const bonusPoints = (state = initState, action: BonusPointsAction): TBonusPointsState => {
    let newState;
    switch (action.type) {
        case Types.SET_BONUS_POINTS_HISTORY:
            newState = { ...state, history: action.payload };
            break;
        case Types.SET_ABOUT_LOYALTY_SYSTEM:
            newState = { ...state, aboutLoyaltySystem: action.payload };
            break;
        case Types.SET_IS_USING_BONUS_POINTS:
            newState = { ...state, isUsingBonusPoints: action.payload };
    }
    return (newState as any) || state;
};

export default bonusPoints;
