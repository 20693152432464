const pointsModal = {
    title: 'Bonuslar',
    rulesTitle: 'Kullanım kuralları',
    historyTitle: 'Tahakkuk tarihi',
    login: 'Puan almak için giriş yapın veya kayıt olun',
    btnAuth: 'Giriş / Kayıt',
    marketing: {
        title: '1 puan = 1 lira',
        subtitle: 'ikramiye nasıl alınır?',
        text:
            'Her bir ürünün fiyatının yanında, bu ürünü satın aldığınızda alacağınız puan sayısı belirtilir. Bonuslar, siparişin başarılı bir şekilde teslim edilmesinden sonra verilir (iptal edilen siparişler ve puanların tamamı veya bir kısmı için ödenen siparişler için ikramiye alınmaz). Bonusları nasıl harcayabilirim? Uygulama aracılığıyla sipariş verirken satın alımlarınızı puanlarla ödeyin.',
    },
    download: {
        start: 'Uygulamayı indirin',
        end: 'ödeme yapmak için bonusları kullanmak için',
    },
    btnDownload: 'Uygulamayı indirin',
};

export default pointsModal;
